import { UnidadesVendidasComponent } from './components/unidades-vendidas/unidades-vendidas.component';
import { HomeComponent } from './components/home/home.component';
// src/app/app.routes.ts
import { Routes } from '@angular/router';
import { authGuard } from './guards/auth.guard';
import { authenticatedGuard } from './guards/authenticated.guard';
import { RoleGuard } from './guards/role-guard.guard';
import { CallbackComponent } from './callback/callback.component';
import { UnidadCrudComponent } from './components/unidades/unidad-crud/unidad-crud.component';
import { LoginComponent } from './auth/login/login.component';
import { AgenciasComponent } from './components/catalogo/agencias/agencias.component';
import { CatalogosComponent } from './components/catalogo/catalogo/catalogo.component';
import { ServiciosComponent } from './components/catalogo/servicios/servicios.component';
import { HistorialServicioComponent } from './components/historial-servicio/historial-servicio.component';
import { TiposUnidadComponent } from './components/tipos-unidad/tipos-unidad.component';


export const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: 'auth/callback',
    component: CallbackComponent
  },
  {
    path: 'home',
    canActivate: [authGuard],
    component: HomeComponent,
  },
  {
    path: 'login',
    canActivate: [authenticatedGuard],
    component: LoginComponent
  },
  {
    path: 'agencias',
    canActivate: [authGuard, RoleGuard],
    data: { roles: ['ROLE_ADMINISTRACION'] },
    component: AgenciasComponent
  },
  {
    path: 'maestro-unidades',
    canActivate: [authGuard, RoleGuard],
    data: { roles: ['ROLE_ADMINISTRACION'] },
    component: UnidadCrudComponent,
  },
  {
    path: 'maestro-unidades/:vin',
    canActivate: [authGuard, RoleGuard],
    data: { roles: ['ROLE_ADMINISTRACION'] },
    component: UnidadCrudComponent,
  },
  {
    path: 'maestro-unidades-vendidas/:vin',
    canActivate: [authGuard, RoleGuard],
    data: { roles: ['ROLE_ADMINISTRACION'] },
    component: UnidadesVendidasComponent
  },
  {
    path: 'maestro-unidades-vendidas',
    canActivate: [authGuard, RoleGuard],
    data: { roles: ['ROLE_ADMINISTRACION'] },
    component: UnidadesVendidasComponent
  },
  // {
  //   path: 'maestro-unidades-vendidas/create/:vin',
  //   canActivate: [authGuard, RoleGuard],
  //   data: { roles: ['ROLE_ADMINISTRACION'] },
  //   component: UnidadesVendidasComponent
  // },
  {
    path: 'historial-servicio/:dominio',
    canActivate: [authGuard, RoleGuard],
    data: { roles: ['ROLE_ADMINISTRACION', 'ROLE_AGENCIA'] },
    component: HistorialServicioComponent
  },
  {
    path: 'historial-servicio',
    canActivate: [authGuard, RoleGuard],
    data: { roles: ['ROLE_ADMINISTRACION', 'ROLE_AGENCIA'] },
    component: HistorialServicioComponent
  },
  // {
  //   path: 'historial-servicio/create/:dominio',
  //   canActivate: [authGuard, RoleGuard],
  //   data: { roles: ['ROLE_ADMINISTRACION', 'ROLE_AGENCIA'] },
  //   component: HistorialServicioComponent
  // },
  {
    path: 'servicios',
    canActivate: [authGuard, RoleGuard],
    data: { roles: ['ROLE_ADMINISTRACION', 'ROLE_AGENCIA'] },
    component: ServiciosComponent
  },
  {
    path: 'catalogo',
    canActivate: [authGuard, RoleGuard],
    data: { roles: ['ROLE_ADMINISTRACION', 'ROLE_AGENCIA'] },
    component: CatalogosComponent
  },
  {
    path: 'tipos-unidad',
    canActivate: [authGuard, RoleGuard],
    data: { roles: ['ROLE_ADMINISTRACION', 'ROLE_AGENCIA'] },
    component: TiposUnidadComponent
  }
];
