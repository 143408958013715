import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../environments/environment';
import { TiposUnidad } from '../models/tipos-unidad.model';

@Injectable({
  providedIn: 'root'
})
export class TiposUnidadService {
  private baseUrl: string = environment.baseUrl;
  private endpoint: string = '/api/tipos-unidad';

  constructor(private http: HttpClient) {}

  // Obtener todos los tipos de unidad
  getAllTiposUnidad(): Observable<TiposUnidad[]> {
    return this.http.get<TiposUnidad[]>(`${this.baseUrl}${this.endpoint}`);
  }

  // Crear un nuevo tipo de unidad
  createTipoUnidad(tipoUnidad: TiposUnidad): Observable<TiposUnidad> {
    return this.http.post<TiposUnidad>(`${this.baseUrl}${this.endpoint}`, tipoUnidad);
  }

  // Actualizar un tipo de unidad existente
  updateTipoUnidad(idTipoUnidad: number, tipoUnidad: TiposUnidad): Observable<TiposUnidad> {
    return this.http.put<TiposUnidad>(`${this.baseUrl}${this.endpoint}/${idTipoUnidad}`, tipoUnidad);
  }

  // Eliminar un tipo de unidad por ID
  deleteTipoUnidad(idTipoUnidad: number): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}${this.endpoint}/${idTipoUnidad}`);
  }

    // Manejo de errores HTTP
    private handleError(error: HttpErrorResponse) {
      // Aquí puedes agregar lógica adicional de manejo de errores
      return throwError(() => new Error('Error en la comunicación con el servidor.'));
    }
}
