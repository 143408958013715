import { NgZone  } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, tap } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../environments/environment';

declare const gapi: any;

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private token: string | null = null;

  private rolesSubject = new BehaviorSubject<string[]>([]);
  public roles$: Observable<string[]> = this.rolesSubject.asObservable();

  private loginUrl = environment.baseUrl + environment.loginUrl;
  private loginUrlGoogle = environment.baseUrl + environment.loginUrlGoogle;


  // Nuevo subject para notificar cambios en el token
  private tokenSubject = new BehaviorSubject<string | null>(null);
  public token$ = this.tokenSubject.asObservable();


  constructor(private httpClient: HttpClient, private router: Router, private ngZone: NgZone) {
    this.loadRolesFromToken();
    // Inicializar el tokenSubject con el token actual
    this.tokenSubject.next(this.getToken());

  }

  private loadRolesFromToken(): void {
    const token = localStorage.getItem('token');
    if (token) {
      const payload = JSON.parse(atob(token.split('.')[1]));
      const roles = payload['roles'] || [];
      this.rolesSubject.next(roles);
    } else {
      this.rolesSubject.next([]);
    }
  }

  loginCredentials(email: string, password: string): Observable<any> {
    // console.log('login user', email, password)

    return this.httpClient.post<any>( this.loginUrl, { email, password }).pipe(
      tap((reponse) => {

        if (!reponse.token && !reponse.accessToken) {
          console.warn('No token found');
          return;
        }

        console.log('Token received via credential login:', reponse.token);

        //this.setToken(reponse.token);
        this.setToken(reponse.token);
        // console.log('Token (loginCredential) saved');

        this.router.navigate(['/home']);

    }));
  }

  public getToken() : string | null{
    const token = localStorage.getItem('token');
    // console.log('Retrieved token from localStorage:', token);

    return token;
  }


  private setToken(token: string) :void {

    let payload = JSON.parse(atob(token.split('.')[1]));

    // console.log('setToken() payload:', payload);

    localStorage.setItem('token', token); // Save the token in localStorage

    this.token = token;

    // const roles = JSON.parse(atob(this.token.split('.')[1]));
    this.rolesSubject.next(payload.roles);

    // console.log('Roles actualizados:', this.roles$);

    //  console.log('Token saved in localStorage:', localStorage.getItem('token'));

  }

  // Método nuevo para actualizar el token (usado por el interceptor)
  public updateToken(newToken: string): void {
    // console.debug('Actualizando token');
    this.setToken(newToken);
    this.tokenSubject.next(newToken);
  }


  hasRole(role: string): boolean {
    // console.log('has role');

    let result: boolean = false;

    this.roles$.subscribe(roles => {
      // console.log('Roles:', roles);
      result = roles.includes(role);
    })

    return result;


    // const token = this.getToken();

    // if (!token) {
    //   // console.log('No token found');
    //   return false;
    // }

    // try {
    //   const payload = JSON.parse(atob(token.split('.')[1]));
    //   console.log('Verificando token ', token);
    //   console.log('Verificando attr roles del payload ', payload);
    //   console.log('Roles:', payload.roles);
    //   console.log('Request:', role);
    //   console.log('Roles boolean:', payload.roles && payload.roles.includes(role));

    //   return payload.roles && payload.roles.includes(role);
    // } catch (error) {
    //   console.error('Error decoding token:', error);
    //   return false;
    // }
  }


  // Método nuevo para verificar si el token está próximo a expirar
  public isTokenNearExpiration(thresholdMinutes: number = 5): boolean {
    const token = this.getToken();
    if (!token) return false;

    try {
      const payload = JSON.parse(atob(token.split('.')[1]));
      const expirationTime = payload.exp * 1000; // Convertir a milisegundos
      const currentTime = Date.now();
      const timeUntilExpiration = expirationTime - currentTime;
      const thresholdMs = thresholdMinutes * 60 * 1000;

      return timeUntilExpiration > 0 && timeUntilExpiration <= thresholdMs;
    } catch (error) {
      console.error('Error al verificar la expiración del token:', error);
      return false;
    }
  }



  hasAnyRole(roles: string[]): boolean {
    return roles.some(role => this.hasRole(role));
  }

   // Modificar isAuthenticated para usar la nueva lógica de expiración
   isAuthenticated(): boolean {
    const token = this.getToken();
    if (!token) return false;

    try {
      const payload = JSON.parse(atob(token.split('.')[1]));
      const exp = payload.exp * 1000; // Convertir a milisegundos
      return Date.now() < exp;
    } catch (error) {
      console.error('Error al verificar autenticación:', error);
      return false;
    }
  }


  logout() {
    localStorage.removeItem('token');
    this.router.navigate(['/login']);

  }

  getRoles(): string[] {
    // console.log('getRoles()');

    const token = this.getToken();
    if (!token) {
      console.warn('getRoles(): No token found');
      return [];
    }

    const payload = JSON.parse(atob(token.split('.')[1]));

    // console.log('getRoles() Roles:', payload.roles);
    return payload.roles;
  }

  loginGoogleLocal(): void {
    window.location.href = this.loginUrlGoogle;
  }

  handleGoogleCallback(token: string): void {

    this.setToken(token);
    // console.log('Token saved from Google new login');

    this.ngZone.run(() => {
      this.router.navigate(['/home']);
    });
  }

}
