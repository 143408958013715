<div class="flex flex-col min-h-screen overflow-hidden relative">
  <ng-container *ngIf="isUserLoggedIn && roles">
     <!-- @menuAnimation -->
    <div class="rounded-3xl text-gray-100 z-50 mt-2" >
    <p-menubar [model]="items" styleClass="mt-1 mb-1 mr-2 ml-2 text-gray-100 "   >
      <ng-template pTemplate="start">
        <!-- <div class="absolute inset-0 flex flex-col items-center justify-center z-0 pointer-events-none" @logoAnimation> -->
        <img src="assets/Kama logo Blue.png" alt="Kama Logo" class="h-8 mr-2" style="border-radius: 125px; border: 2px solid black;">
        <!-- </div> -->
      </ng-template>
      <ng-template pTemplate="end">
        <div class="flex items-center">
          <span class="" style="color: dimgrey; font-weight: bold;" > {{ userName }} - </span><span class="m4" style="color: dimgray"> ({{ rolesDescription }})</span>
          <p-button pButton type="button" styleClass="p-button-sm" icon="pi pi-sign-out" class="p-button-danger custom-logout-button" (click)="logout(); userName = null; isUserLoggedIn = false;" label="Logout"></p-button>
        </div>
      </ng-template>
    </p-menubar>
  </div>
  </ng-container>
  <div class="absolute inset-0 flex flex-col items-center justify-center z-0 pointer-events-none" name="div-imagen-body">
    <img src="assets/LogoWebKama-01-1.png" alt="Logo" class="mx-auto logo-kama">
  </div>
  <div class="flex-grow mt-2 w-full h-full z-10">
    <router-outlet></router-outlet>
  </div>
  <footer class="w-full bg-gray-800 text-gray-200 py-4 text-center z-20">
    <p>&copy; 2024 Kama - Todos los derechos reservados.</p>
  </footer>
</div>
