import { TitularService } from './../../services/titular.service';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { UnidadVendida } from '../../models/unidades-vendidas.model';
import { AutoCompleteCompleteEvent } from 'primeng/autocomplete';
import { Unidad } from '../../models/unidad-maestro.model';
import { UnidadService } from '../../services/unidad-maestro.service';
import { UnidadesVendidasService } from '../../services/unidades-vendidas.service';
import { Titular } from '../../models/titular.model';

import { AgenciaService } from '../../services/agencia.service';
import { Agencia } from '../../models/agencia.model';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastItem } from 'primeng/toast';

@Component({
  selector: 'app-unidades-vendidas',
  templateUrl: './unidades-vendidas.component.html',
  styleUrls: ['./unidades-vendidas.component.scss'],
  providers: [ConfirmationService, MessageService]
})
export class UnidadesVendidasComponent implements OnInit {

  unidadesVendidas: UnidadVendida[] = [];
  selectedUnidadVendida: UnidadVendida | null = null;
  unidadVendidaForm: FormGroup;
  isCreating: boolean = false;
  deleteError: string | null = null;
  deleteSuccess: string | null = null;
  vinSearch: string = '';
  isEditing: boolean = false;
  vinsCargados: string[] = [];
  unidades: Unidad[] = [];
  selectedUnidad: Unidad | null = null;
  agencias: { label: string, value: any }[] = [];
  isCreateMode: boolean = false;



  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private unidadesVendidasService: UnidadesVendidasService,
    private agenciaService: AgenciaService,
    private confirmationService: ConfirmationService,
    private unidadService: UnidadService,
    private titularService: TitularService,
    private messageService: MessageService
  ) {
    this.unidadVendidaForm = this.fb.group({
      dominio: new FormControl({ value: '', disabled: true }, Validators.required),
      vin: new FormControl({ value: '', disabled: true }, [Validators.required, Validators.maxLength(24)]),
      tipoUnidad: new FormControl({ value: '', disabled: true }, [Validators.required, Validators.maxLength(3)]),
      idUnidad: new FormControl({ value: '', disabled: true }, [Validators.required, Validators.maxLength(20)]),
      nroMotor: new FormControl({ value: '', disabled: true }, [Validators.required, Validators.maxLength(24)]),
      idAgencia: new FormControl({ value: null, disabled: true }, Validators.required),
      kmEntrega: new FormControl({ value: '', disabled: true }, Validators.required),
      kmGarantia: new FormControl({ value: '', disabled: true }, Validators.required),
      fechaGarantia: new FormControl({ value: '', disabled: true }, Validators.required),
      titulares: this.fb.array([])
    });
  }

  get titulares(): FormArray {
    return this.unidadVendidaForm.get('titulares') as FormArray;
  }

  addTitular(): void {
    const titularForm = this.fb.group({
      dominio: [{ value: '', disabled: !this.isEditing && !this.isCreating }],
      fechaTitularidad: [{ value: '', disabled: !this.isEditing && !this.isCreating }],
      nombre: [{ value: '', disabled: !this.isEditing && !this.isCreating }],
      cuitCuil: [{ value: '', disabled: !this.isEditing && !this.isCreating }],
      // movil: [{ value: '', disabled: !this.isEditing && !this.isCreating }],
      correo: [{ value: '', disabled: !this.isEditing && !this.isCreating }],
      direccion: [{ value: '', disabled: !this.isEditing && !this.isCreating }],
      calle: [{ value: '', disabled: !this.isEditing && !this.isCreating }],
      numero: [null, Validators.required],
      provincia: [{ value: '', disabled: !this.isEditing && !this.isCreating }],
      localidad: [{ value: '', disabled: !this.isEditing && !this.isCreating }],
      codPostal: ['', Validators.required]
    });
    this.titulares.push(titularForm);
  }


  removeTitular(index: number): void {
    this.titulares.removeAt(index);
  }

  ngOnInit(): void {

    this.route.params.subscribe(params => {

      this.loadUnidadesVendidas();
      this.loadMaestroUnidades();
      this.loadAgencias(); // Cargar agencias al inicializar el componente

      console.log(params);

      this.isCreateMode = this.route.snapshot.url.some(segment => segment.path === 'create');

      if (this.isCreateMode) {

        // console.log('isCreateMode:', this.isCreateMode);

        if (!params['vin']) {
          //error
          console.error('error no hay vin en la ruta');
          return;
        }

        this.vinSearch = params['vin'] || '';

        this.nuevoRegistro();

        console.log('Vin en ruta:', this.vinSearch);

        if (this.vinSearch) {
          this.searchUnidadAndLoadAttrs(this.vinSearch);
        }

        console.log(this.vinSearch);
      }
      else {

          console.log('should not be in CreateMode:', this.isCreateMode);

          if (params['vin']) {
            this.vinSearch = params['vin'] || '';

            if (this.vinSearch) {
              this.searchUnidadVendidaVin(this.vinSearch);
            }
            console.log(this.vinSearch);
          }
      }
    });




  }


  loadAgencias(): void {
    this.agenciaService.getAll().subscribe(
      (data: Agencia[]) => {
        // console.log('Agencias cargadas:', data);  // Aquí verifica la respuesta de la API

        // Transforma el array para que tenga las propiedades label y value
        this.agencias = data.map(agencia => ({
          label: agencia.nombre,
          value: agencia.idAgencia
        }));

        ;
      },
      (error) => {
        // console.log('Error al cargar agencias:', error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al cargar agencias' });
      }
    );
  }

  loadMaestroUnidades(): void {
    this.unidadService.getUnidades().subscribe(
      (data: Unidad[]) => {
        // console.log('Unidades cargadas:', data);  // Aquí verifica la respuesta de la API

        this.unidades = data;
        this.vinsCargados = this.unidades.map(unidad => unidad.vin);
      },
      (error) => {
        // console.log('Error al cargar unidades:', error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al cargar unidades' });
      }
    );
  }

  loadUnidadesVendidas(): void {
    this.unidadesVendidasService.getAllUnidadesVendidas().subscribe(
      (data: UnidadVendida[]) => {
        // console.log('Unidades vendidas cargadas:', data);  // Aquí verifica la respuesta de la API
        this.unidadesVendidas = data;

        // console.log('isCreating:', this.isCreating + ' isEditing:', this.isEditing);
        // this.vinsCargados = this.unidadesVendidas.map(unidad => unidad.vin);
      },
      (error) => {
        console.log('Error al cargar unidades vendidas:', error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al cargar unidades vendidas' });
      }
    );
  }

  searchVin(event: AutoCompleteCompleteEvent) {
    let filtered: string[] = [];
    let query = event.query;

    for (let unidad of this.unidades) {
        if (unidad.vin.toLowerCase().indexOf(query.toLowerCase()) == 0) {
            filtered.push(unidad.vin);
        }
    }

    this.vinsCargados = filtered;
  }

  searchUnidadGeneral(event: any): void {
    const vin = event.query;
    this.searchUnidadGeneralvin(vin);
  }



  searchUnidadAndLoadAttrs(event: any): void {
    let vin;

    if (!event.value && !event.query) {
      vin=event;
    } else {
      vin = event.value;
    }

    if (!vin) {
      console.error('No se ha ingresado un VIN (searchUnidadAndLoadAttrs)');
      console.log('No se ha ingresado un VIN (searchUnidadAndLoadAttrs)');
      return;
    }

    // console.log('Buscando unidad con vin(event query):', event.query);
    console.log('Buscando unidad con vin(event value):', event.value);

    this.unidadService.getUnidadesByVin(vin).subscribe(
      (unidadArray: Unidad[]) => {
        // console.log('Unidad encontrada:', unidadArray);

        if (unidadArray && unidadArray.length > 0) {
          this.selectedUnidad = unidadArray[0]; // Acceder al primer elemento del array
          // console.log('selectedUnidad:', this.selectedUnidad);
          this.unidadVendidaForm.get('tipoUnidad')?.setValue(this.selectedUnidad?.tipoUnidad);
          this.unidadVendidaForm.get('idUnidad')?.setValue(this.selectedUnidad?.idUnidad);
          this.unidadVendidaForm.get('nroMotor')?.setValue(this.selectedUnidad?.nroMotor);

          // this.unidadVendidaForm.get('tipoUnidad')?.updateValueAndValidity();

        } else {
          console.warn('No se encontró ninguna unidad.');
        }
      },
      (error) => {
        console.log('Error al buscar unidad:', error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Unidad no encontrada' });
      }
    );

  }

  searchUnidadGeneralvin(pvin?: string): void {
    const vin = pvin ? pvin : this.vinSearch;

    console.log('Buscando unidad con vinSearch:', this.vinSearch, 'pvin: ', pvin);
    this.unidadService.getUnidadesByVin(vin).subscribe(
      (unidadArray: Unidad[]) => {
        console.log('(searchUnidadGeneralvin) Unidad encontrada:', unidadArray);

        if (unidadArray && unidadArray.length > 0) {
          this.selectedUnidad = unidadArray[0]; // Acceder al primer elemento del array
          // console.log('selectedUnidad:', this.selectedUnidad);
        } else {
          console.warn('No se encontró ninguna unidad.');
        }
      },
      (error) => {
        console.log('Error al buscar unidad:', error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Unidad no encontrada' });
      }
    );
  }

  searchUnidadVendida(event: any): void {
    const vin = event.value;
    console.log('event: ', event);

    // this.messageService.add({ severity: 'success', summary: 'Debug', detail: 'vin=' + vin });
    this.searchUnidadVendidaVin(vin);
  }

  nuevoRegistro(): void {
    // this.selectedUnidadVendida = null;
    // este lo agregó gpt y no se si hace falta

    this.unidadVendidaForm.reset();
    this.isEditing = true;
    this.isCreating = true;
    this.selectedUnidad = null;
    this.enableForm();

    this.unidadVendidaForm.get('nroMotor')?.disable();
    this.unidadVendidaForm.get('nroMotor')?.updateValueAndValidity();
    this.unidadVendidaForm.get('tipoUnidad')?.disable();
    this.unidadVendidaForm.get('tipoUnidad')?.updateValueAndValidity();
    this.unidadVendidaForm.get('idUnidad')?.disable();
    this.unidadVendidaForm.get('idUnidad')?.updateValueAndValidity();

  }

  searchUnidadVendidaVin(pvin?: string): void {
    let vin;

    if (pvin) {
      // this.vinSearch = pvin;
      vin = pvin;
    }
    else {
      vin = this.vinSearch;
    }

    // this.messageService.add({ severity: 'success', summary: 'Debug', detail: 'vin=' + vin });

    this.unidadesVendidasService.searchUnidadesVendidas(vin).subscribe((data: UnidadVendida[]) => {
      if (data.length > 0) {
        this.selectedUnidadVendida = data[0];

        console.log('selectedUnidadVendida:', this.selectedUnidadVendida);

        this.unidadVendidaForm.patchValue(this.selectedUnidadVendida);


        // this.messageService.add({ severity: 'success', summary: 'Debug2', detail: 'selectedUnidadVendida=' + JSON.stringify(this.selectedUnidadVendida) });

        this.titulares.clear();

        // Realizar la carga de titulares usando el dominio como argumento y cargarlos en el formulario
        if (this.selectedUnidadVendida) {
            this.titularService.getTitularesByDominio(this.selectedUnidadVendida.dominio).subscribe({
            next: (titulares: Titular[]) => {

              // const titulares = [titularx];
              // console.log('Titulares cargados:', titulares);

              if (Array.isArray(titulares)) {
                titulares.forEach((titular) => {

                  console.log(titular.correo);
                  console.log(JSON.stringify(titular));
                  console.log(JSON.stringify(titular.correo));

                  const titularForm = this.fb.group({
                    fechaTitularidad: [{ value: titular.fechaTitularidad, disabled: !this.isEditing && !this.isCreating }],
                    nombre: [{ value: titular.nombre, disabled: !this.isEditing && !this.isCreating }],
                    cuitCuil: [{ value: titular.cuitCuil, disabled: !this.isEditing && !this.isCreating }],
                    // movil: [{ value: '', disabled: !this.isEditing && !this.isCreating }],
                    correo: [{ value: titular.correo, disabled: !this.isEditing && !this.isCreating }],
                    direccion: [{ value: titular.direccion, disabled: !this.isEditing && !this.isCreating }],
                    calle: [{ value: titular.calle, disabled: !this.isEditing && !this.isCreating }],
                    numero: [{value: titular.numero, disabled: !this.isEditing && !this.isCreating }],
                    provincia: [{ value: titular.provincia, disabled: !this.isEditing && !this.isCreating }],
                    localidad: [{ value: titular.localidad, disabled: !this.isEditing && !this.isCreating }],
                    codPostal: [{ value: titular.codPostal, disabled: !this.isEditing && !this.isCreating }],
                  });
                  this.titulares.push(titularForm);
                });
              } else {
                console.error('Expected titulares to be an array, but got:', titulares);
              }
            },
            error: (error) => {
              console.log('Error al cargar titulares:', error);
              this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al cargar titulares' });
            }
            });
        }

        this.isEditing = false;
        this.isCreating = false;
        this.disableForm();

        // Lleva al usuario al inicio de la pagina
        window.scrollTo(0, 0);

        // this.messageService.add({ severity: 'success', summary: 'Debug2', detail: 'selectedUnidadVendida=' + this.selectedUnidadVendida });
        // console.log('selectedUnidadVendida:', this.selectedUnidadVendida);

      } else {
        this.selectedUnidadVendida = null;
        this.unidadVendidaForm.reset();
        console.warn('No se encontró ninguna unidad.');

      }
    });
  }

  createUnidadVendida(): void {

    let faltanCampos=false;
    let creatingVin = this.unidadVendidaForm.get('vin')?.value;

    // Ensure the values are set before validation
    this.unidadVendidaForm.get('tipoUnidad')?.setValue(this.selectedUnidad?.tipoUnidad);
    this.unidadVendidaForm.get('idUnidad')?.setValue(this.selectedUnidad?.idUnidad);
    this.unidadVendidaForm.get('nroMotor')?.setValue(this.selectedUnidad?.nroMotor);

    console.log('Formulario:', this.unidadVendidaForm.value);  // Verifica que los valores sean correctos

    // Log de errores de cada control antes de guardar
    Object.keys(this.unidadVendidaForm.controls).forEach(key => {
      const controlErrors = this.unidadVendidaForm.get(key)?.errors;

      console.log('Control:', key, 'Errores:', controlErrors, 'Valor:', this.unidadVendidaForm.get(key)?.value);

      if (controlErrors) {
        console.log(`Errores en el campo ${key}:`, controlErrors);

        //Marca el input como ng-dirty o algo similar
        this.unidadVendidaForm.get(key)?.markAsDirty();
        this.unidadVendidaForm.get(key)?.markAsTouched();

        faltanCampos=true;
      }
    });

    if (this.titulares.length === 0) {
      console.log('No se han agregado titulares');
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Agregue al menos un titular' });
      faltanCampos=true;
      return;
    }

    // Log de errores de cada titular antes de guardar
    this.titulares.controls.forEach((titularForm, index) => {
      Object.keys((titularForm  as FormGroup).controls).forEach(key => {
        const controlErrors = titularForm.get(key)?.errors;
        console.log('Control:', key, 'Errores:', controlErrors, 'Valor:', titularForm.get(key)?.value);

        if (controlErrors) {
          console.log(`Errores en el campo ${key}:`, controlErrors);

          //Marca el input como ng-dirty o algo similar
          titularForm.get(key)?.markAsDirty();
          titularForm.get(key)?.markAsTouched();

          faltanCampos=true;
        }
      });
    });


    if(faltanCampos){
      this.messageService.add({severity:'error', summary: 'Error', detail: 'Formulario inválido: Complete los campos obligatorios'});
      return;
    }

    console.log('Creando unidad:', this.unidadVendidaForm.getRawValue());

    if (this.unidadVendidaForm.valid) {

      this.unidadesVendidasService.createUnidadVendida(this.unidadVendidaForm.getRawValue()).subscribe({
        next: () => {

          // llama al metodo crear titular del servicio de titulares
          this.titulares.controls.forEach((titularForm, index) => {

            let titular: Titular = {
              dominio: this.unidadVendidaForm.get('dominio')?.value,
              ...titularForm.getRawValue()
            };

            // transforma la fecha de titularidad en un objeto de tipo string en formayo yyyy-mm-dd
            if (titular.fechaTitularidad) {

              // crea un objeto string que tenga la fecha de titularidad en formato yyyy-mm-dd
              console.log('Fecha titularidad:', titular.fechaTitularidad);

              // titular.fechaTitularidad = fechaTitularidad;
            }

            console.log(titular);
            titular.dominio = this.unidadVendidaForm.get('dominio')?.value;
            console.log(titular);

            this.titularService.createTitular(titular).subscribe({
              next: () => {
                console.log('Titular creado exitosamente');
              },
              error: (error) => {
                console.log('Error al crear titular:', error);
                this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al crear titular' });
              }
            });
          });


          const newUnidadVendida = this.unidadVendidaForm.getRawValue() as UnidadVendida;
          this.unidadesVendidas.push(newUnidadVendida);
          this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Unidad Vendida creada exitosamente' });

          this.loadUnidadesVendidas();
          this.isEditing = false;
          this.isCreating = false;
          this.disableForm();
          this.searchUnidadVendidaVin(creatingVin);
        },
        error: (error) => {
          console.log('Error al crear unidad vendida:', error);
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al crear unidad vendida: ' + error.error.details});
        }
      });
    }
  }

  callHistorialServicios(): void {
    this.router.navigate(['/historial-servicio/', this.selectedUnidadVendida?.dominio]);
  }

  editarUnidadVendida(): void {
    if (this.selectedUnidadVendida) {
      this.isEditing = true;
      this.isCreating = false;
      this.enableForm();

      this.unidadVendidaForm.get('nroMotor')?.disable();
      this.unidadVendidaForm.get('nroMotor')?.updateValueAndValidity();
      this.unidadVendidaForm.get('tipoUnidad')?.disable();
      this.unidadVendidaForm.get('tipoUnidad')?.updateValueAndValidity();
      this.unidadVendidaForm.get('idUnidad')?.disable();
      this.unidadVendidaForm.get('idUnidad')?.updateValueAndValidity();
    }
    else {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Seleccione una unidad vendida para editar' });
    }
  }

  updateUnidadVendida(): void {

    let faltanCampos=false;

    // Ensure the values are set before validation
    this.unidadVendidaForm.get('tipoUnidad')?.setValue(this.selectedUnidadVendida?.tipoUnidad);
    this.unidadVendidaForm.get('idUnidad')?.setValue(this.selectedUnidadVendida?.idUnidad);
    this.unidadVendidaForm.get('nroMotor')?.setValue(this.selectedUnidadVendida?.nroMotor);

    // Log de errores de cada control antes de guardar
    Object.keys(this.unidadVendidaForm.controls).forEach(key => {
      const controlErrors = this.unidadVendidaForm.get(key)?.errors;
      if (controlErrors) {
        console.log(`Errores en el campo ${key}:`, controlErrors);

        //Marca el input como ng-dirty o algo similar
        this.unidadVendidaForm.get(key)?.markAsDirty();

        faltanCampos=true;
      }
    });


    // Log de errores de cada titular antes de guardar
    this.titulares.controls.forEach((titularForm, index) => {
      Object.keys((titularForm  as FormGroup).controls).forEach(key => {
        const controlErrors = titularForm.get(key)?.errors;
        // console.log('Control:', key, 'Errores:', controlErrors, 'Valor:', titularForm.get(key)?.value);

        if (controlErrors) {
          // console.log(`Errores en el campo ${key}:`, controlErrors);

          //Marca el input como ng-dirty o algo similar
          titularForm.get(key)?.markAsDirty();
          titularForm.get(key)?.markAsTouched();

          faltanCampos=true;
        }
      });
    });

    if(faltanCampos){
      this.messageService.add({severity:'error', summary: 'Error', detail: 'Formulario inválido: Complete los campos obligatorios'});
      return;
    }

    if (this.unidadVendidaForm.valid && this.selectedUnidadVendida) {
      // Object.assign(this.selectedUnidadVendida, this.unidadVendidaForm.value);


      if (this.titulares.length > 0) {
        this.titulares.controls.forEach((titularForm, index) => {
          let titular: Titular = {
            dominio:
            this.unidadVendidaForm.get('dominio')?.value,
            ...titularForm.getRawValue()
          };

          this.titularService.deleteTitularesByDominio(this.unidadVendidaForm.get('dominio')?.value).subscribe({
            next: () => {
              console.log('Titular eliminado exitosamente');
            },
            error: (error) => {
              console.log('Error al eliminar titular:', error);
              // this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al eliminar titular' });
            }
          });


          // transforma la fecha de titularidad en un objeto de tipo string en formayo yyyy-mm-dd
          if (titular.fechaTitularidad) {
            const [year, month, day] = titular.fechaTitularidad.split('-');
            console.log('year:', year, 'month:', month, 'day:', day);

            // console.log('Fecha titularidad:', fechaTitularidad);
          }

          console.log(titular);
          titular.dominio = this.unidadVendidaForm.get('dominio')?.value;
          console.log(titular);

          this.titularService.createTitular(titular).subscribe({
            next: () => {
              console.log('Titular creado exitosamente');
              this.messageService.add({ severity: 'success', summary: 'Exito', detail: 'Titular actualizado' });
            },
            error: (error) => {
              console.log('Error al crear titular:', error);
              this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al crear titular' });
            }
          });

        });
      }


      this.unidadesVendidasService.updateUnidadVendida(this.selectedUnidadVendida.dominio, this.unidadVendidaForm.getRawValue()).subscribe(
        () => {
          this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Unidad Vendida actualizada exitosamente' });

          this.loadUnidadesVendidas();
          this.searchUnidadVendidaVin(this.unidadVendidaForm.get('vin')?.value);
          this.isEditing = false;
          this.isCreating = false;
          this.disableForm();
          //this.unidadForm.reset();

        },
        (error) => {
          console.log('Error al actualizar unidad vendida:', error);
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al actualizar unidad vendida' });
        }
      );
    }
  }

  deleteUnidadVendida(): void {
    if (this.selectedUnidadVendida) {
      this.confirmationService.confirm({
        message: '¿Está seguro de que desea eliminar esta unidad vendida?',
        accept: () => {

          if (this.titulares.length > 0) {
            this.titulares.controls.forEach((titularForm, index) => {
              const titular: Titular = {
                dominio: this.unidadVendidaForm.get('dominio')?.value,
                ...titularForm.getRawValue()
              };

              this.titularService.deleteTitularesByDominio(this.unidadVendidaForm.get('dominio')?.value).subscribe({
                next: () => {
                  console.log('Titular eliminado exitosamente');
                },
                error: (error) => {
                  console.log('Error al eliminar titular:', error);
                  this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al eliminar titular' });
                }
              });
            });
          }


          this.titulares.controls.forEach((titularForm, index) => {

            const titular: Titular = {
              dominio: this.unidadVendidaForm.get('dominio')?.value,
              ...titularForm.getRawValue()
            };

            this.titularService.createTitular(titular).subscribe({
              next: () => {
                console.log('Titular creado exitosamente');
              },
              error: (error) => {
                console.log('Error al crear titular:', error);
                this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al crear titular' });
              }
            });
          });

          this.unidadesVendidasService.deleteUnidadVendida(this.selectedUnidadVendida!.dominio).subscribe(
            () => {
              this.unidadesVendidas = this.unidadesVendidas.filter(u => u !== this.selectedUnidadVendida);
              this.selectedUnidadVendida = null;
              this.deleteSuccess = 'Unidad Vendida eliminada exitosamente';
              this.messageService.add({ severity: 'success', summary: 'Éxito', detail: this.deleteSuccess });
              this.loadUnidadesVendidas();
              this.unidadVendidaForm.reset();
              this.isEditing = false;
              this.selectedUnidad = null;
              this.disableForm();

            },
            );
        },
        reject: () => {
          this.deleteError = 'Eliminación cancelada';
        }
      });
    }
  }

  cancelEdit(): void {
    this.unidadVendidaForm.reset();
    this.isEditing = false;
    this.isCreating = false;
    this.selectedUnidadVendida = null;
    this.selectedUnidad = null;
    this.disableForm();
  }

  consultarUnidadVendida(unidadVendida: UnidadVendida): void {
    // this.selectedUnidadVendida = unidadVendida;
    // this.unidadVendidaForm.patchValue(unidadVendida);
    this.vinSearch = unidadVendida.vin;
    this.searchUnidadVendidaVin(unidadVendida.vin);
  }

  enableForm(): void {
    Object.keys(this.unidadVendidaForm.controls).forEach(controlName => {
      const control = this.unidadVendidaForm.get(controlName);
      // console.log('Habilitando control:', controlName);
      control?.enable();
      control?.updateValueAndValidity(); // Asegúrate de actualizar la validez
    });
    // console.log('Formulario habilitado y actualizado:', this.unidadVendidaForm);
    Object.keys(this.unidadVendidaForm.controls).forEach(controlName => {
      this.unidadVendidaForm.get(controlName)?.enable();
    });
  }

  disableForm(): void {
    Object.keys(this.unidadVendidaForm.controls).forEach(controlName => {
      this.unidadVendidaForm.get(controlName)?.disable();
    });
  }

}
