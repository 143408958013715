// catalogos.component.ts
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CatalogoService } from '../../../services/catalogo.service';
import { Catalogo } from '../../../models/catalogo.model';
import { MessageService } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-catalogos',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.scss'],
  providers: [MessageService, ConfirmationService]
})
export class CatalogosComponent implements OnInit {
  catalogoForm: FormGroup;
  catalogos: Catalogo[] = [];
  searchText: string = '';
  selectedCatalogo: Catalogo | null = null;
  deleteError: string | null = null;
  deleteSuccess: string | null = null;
  isCreating: boolean = false;
  isEditing: boolean = false;
  catalogo: Catalogo | null = null;


  constructor(
    private fb: FormBuilder,
    private catalogoService: CatalogoService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService
  ) {


    this.catalogoForm = this.fb.group({

      tipoUnidad: ['', Validators.required],
      idUnidad: [null, Validators.required],
      nombre: ['', Validators.required],
      nombreChino: [''],
      nombreIngles: [''],
      unidadesEmbalaje: [null, [Validators.required, Validators.min(1)]],
      stockMinimoRecomendado: [null, [Validators.required, Validators.min(0)]],
      valorRef: [null, [Validators.required, Validators.min(0)]],
      idMoneda: ['', Validators.required],
      fechaRef: ['', Validators.required],
      idSeccion: [null, Validators.required],
      idSubseccion: [null, Validators.required],
      nombreSeccion: ['', Validators.required],
      nombreSubseccion: ['', Validators.required],
      simboloMoneda: ['', Validators.required]

    });
  }

  ngOnInit(): void {
    // this.obtenerCatalogos();
  }

  editarCatalogo(): void {
  }

  createCatalogo(): void {
  }

  consultarCatalogo(): void {
  }

  cancelEdit(): void {
  }

  updateCatalogo(): void {
  }

  nuevoCatalogo(): void {
  }


   obtenerCatalogos(): void {
   }
  //   this.catalogoService.obtenerTodos().subscribe(
  //     (catalogos) => {
  //       this.catalogos = catalogos;
  //     },
  //     (error) => {
  //       this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al obtener los catálogos.' });
  //     }
  //   );
  // }

   buscarCatalogo(): void {
   }
  //   if (this.searchText.trim()) {
  //     this.catalogoService.buscarCatalogo(this.searchText).subscribe(
  //       (catalogos) => {
  //         this.catalogos = catalogos;
  //       },
  //       (error) => {
  //         this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al buscar el catálogo.' });
  //       }
  //     );
  //   } else {
  //     this.obtenerCatalogos();
  //   }
  // }

   guardarCatalogo(): void {
   }
  //   if (this.catalogoForm.valid) {
  //     const nuevoCatalogo = this.catalogoForm.value as CatalogoDTO;
  //     this.catalogoService.crearCatalogo(nuevoCatalogo).subscribe(
  //       () => {
  //         this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Catálogo creado exitosamente.' });
  //         this.catalogoForm.reset();
  //         this.obtenerCatalogos();
  //       },
  //       (error) => {
  //         this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al crear el catálogo.' });
  //       }
  //     );
  //   }
  // }

   seleccionarCatalogo(catalogo: Catalogo): void {
   }
  //   this.selectedCatalogo = catalogo;
  //   this.catalogoForm.patchValue(catalogo);
  // }

   actualizarCatalogo(): void {
   }
  //   if (this.selectedCatalogo && this.catalogoForm.valid) {
  //     const catalogoActualizado = this.catalogoForm.value as CatalogoDTO;
  //     this.catalogoService.actualizarCatalogo(this.selectedCatalogo.tipoUnidad, this.selectedCatalogo.idUnidad, catalogoActualizado).subscribe(
  //       () => {
  //         this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Catálogo actualizado exitosamente.' });
  //         this.catalogoForm.reset();
  //         this.selectedCatalogo = null;
  //         this.obtenerCatalogos();
  //       },
  //       (error) => {
  //         this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al actualizar el catálogo.' });
  //       }
  //     );
  //   }
  // }

   eliminarCatalogo(): void {
   }
  //   this.catalogoService.eliminarCatalogo(catalogo.tipoUnidad, catalogo.idUnidad).subscribe(
  //     () => {
  //       this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Catálogo eliminado exitosamente.' });
  //       this.obtenerCatalogos();
  //     },
  //     (error) => {
  //       this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al eliminar el catálogo.' });
  //     }
  //   );
  // }
}
