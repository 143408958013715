// Service: TitularService
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { Titular } from '../models/titular.model';


@Injectable({
  providedIn: 'root',
})
export class TitularService {
  private apiUrl = `${environment.baseUrl}/api/titulares`;

  constructor(private http: HttpClient) {}

  // Obtener todos los titulares
  getAllTitulares(): Observable<Titular[]> {
    return this.http.get<Titular[]>(this.apiUrl);
  }

  // Obtener un titular por ID
  getTitularById(id: number): Observable<Titular> {
    return this.http.get<Titular>(`${this.apiUrl}/${id}`);
  }

  // Crear un nuevo titular
  createTitular(titular: Titular): Observable<Titular> {
    return this.http.post<Titular>(this.apiUrl, titular);
  }

  // Actualizar un titular existente
  updateTitular(id: number, titular: Titular): Observable<Titular> {
    return this.http.put<Titular>(`${this.apiUrl}/${id}`, titular);
  }

  // Eliminar titulares by dominio
  deleteTitularesByDominio(dominio: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/dominio/${dominio}`);
  }

  // Obtener titulares por dominio
  getTitularesByDominio(parametro: string): Observable<Titular[]> {
    return this.http.get<Titular[]>(`${this.apiUrl}/dominio/${parametro}`);
  }
}
