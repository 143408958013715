import { HistorialServicioService } from './../../services/historial-servicio.service';
import { TitularService } from './../../services/titular.service';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { UnidadVendida } from '../../models/unidades-vendidas.model';
import { AutoCompleteCompleteEvent, AutoCompleteSelectEvent } from 'primeng/autocomplete';
// import { Unidad } from '../../models/unidad-maestro.model';
import { UnidadService } from '../../services/unidad-maestro.service';
import { UnidadesVendidasService } from '../../services/unidades-vendidas.service';
import { Titular } from '../../models/titular.model';
import { AgenciaService } from '../../services/agencia.service';
import { Agencia } from '../../models/agencia.model';
import { ActivatedRoute } from '@angular/router';
import { HistorialServicio } from '../../models/historial-servicio.model'

@Component({
  selector: 'app-historial-servicio',
  templateUrl: './historial-servicio.component.html',
  styleUrls: ['./historial-servicio.component.scss'],
  providers: [ConfirmationService, MessageService]
})
export class HistorialServicioComponent implements OnInit {

  historialServicios: HistorialServicio[] = [];
  selectedHistorialServicio: HistorialServicio | null = null;
  historialServicioForm: FormGroup;
  isCreating: boolean = false;
  deleteError: string | null = null;
  deleteSuccess: string | null = null;
  dominioSearch: string = '';
  isEditing: boolean = false;
  dominiosCargados: string[] = [];
  // unidades: Unidad[] = [];
  // selectedUnidad: Unidad | null = null;
  garantiaOptions: { label: string, value: any }[] = [{ label: 'Sí', value: true }, { label: 'No', value: false }];
  isCreateMode: boolean = false;
  unidadesVendidas: UnidadVendida[] = [];
  selectedUnidadVendida: UnidadVendida | null = null;
  agencias: { label: string, value: any }[] = [];


  constructor(
    private fb: FormBuilder,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private unidadesVendidasService: UnidadesVendidasService,
    private historialServicioService: HistorialServicioService,
    private agenciaService: AgenciaService,
    private unidadService: UnidadService,
    private titularService: TitularService,
  ) {
    this.historialServicioForm = this.fb.group({
      dominio: new FormControl({ value: '', disabled: true }, Validators.required),
      fechaServicio: new FormControl({ value: '', disabled: true }, Validators.required),
      idAgencia: new FormControl({ value: '', disabled: true }, Validators.required),
      kmIngreso: new FormControl({ value: '', disabled: true }, Validators.required),
      garantia: new FormControl({ value: '', disabled: true }, Validators.required),
      idServicio: new FormControl({ value: '', disabled: true }),
      idTarea: new FormControl({ value: '', disabled: true }),
      comentarios: new FormControl({ value: '', disabled: true }),
      tipoUnidad: new FormControl({ value: '', disabled: true }, Validators.required),
      idUnidad: new FormControl({ value: '', disabled: true }, Validators.required)
    });
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {

      this.loadUnidadesVendidas();
      this.loadHistorialServicios();
      this.loadAgencias(); // Cargar agencias al inicializar el componente


      // this.loadMaestroUnidades();
      console.log(params);
      this.isCreateMode = this.route.snapshot.url.some(segment => segment.path === 'create');
      if (this.isCreateMode) {

        console.log('isCreateMode:', this.isCreateMode);

        if (!params['dominio']) {
          //error
          console.error('error no hay dominio en la ruta');
          return;
        }

        this.dominioSearch = params['dominio'] || '';
        this.nuevoRegistro();

        console.log('dominio en ruta:', this.dominioSearch);

        if (this.dominioSearch) {
          this.searchUnidadVendidaAndLoadAttrs(this.dominioSearch);
        }

        console.log(this.dominioSearch);
      }
      else {

        console.log('should not be in CreateMode:', this.isCreateMode);

        if (params['dominio']) {
          this.dominioSearch = params['dominio'] || '';
          console.log(this.dominioSearch);

          if (this.dominioSearch) {
            this.searchHistorialServicioDominio(this.dominioSearch);
          }

        }
      }
    });
  }

  loadAgencias(): void {
    this.agenciaService.getAll().subscribe(
      (data: Agencia[]) => {
        // console.log('Agencias cargadas:', data);  // Aquí verifica la respuesta de la API

        // Transforma el array para que tenga las propiedades label y value
        this.agencias = data.map(agencia => ({
          label: agencia.nombre,
          value: agencia.idAgencia
        }));

        ;
      },
      (error) => {
        // console.log('Error al cargar agencias:', error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al cargar agencias' });
      }
    );
  }

  // loadMaestroUnidades(): void {
  //   this.unidadService.getUnidades().subscribe(
  //     (data: Unidad[]) => {
  //       console.log('Unidades cargadas:', data);  // Aquí verifica la respuesta de la API

  //       this.unidades = data;
  //       this.vinsCargados = this.unidades.map(unidad => unidad.vin);
  //     },
  //     (error) => {
  //       // console.log('Error al cargar unidades:', error);
  //       this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al cargar unidades' });
  //     }
  //   );
  // }

  loadUnidadesVendidas(): void {
    this.unidadesVendidasService.getAllUnidadesVendidas().subscribe(
      (data: UnidadVendida[]) => {
        console.log('Unidades vendidas cargadas:', data);  // Aquí verifica la respuesta de la API
        this.unidadesVendidas = data;
        this.dominiosCargados = this.unidadesVendidas.map(unidad => unidad.dominio);

        console.log('isCreating:', this.isCreating + ' isEditing:', this.isEditing);
        // this.vinsCargados = this.unidadesVendidas.map(unidad => unidad.vin);
      },
      (error) => {
        console.log('Error al cargar unidades vendidas:', error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al cargar unidades vendidas' });
      }
    );
  }

  loadHistorialServicios(): void {
    this.historialServicioService.getAll().subscribe(
      (data: HistorialServicio[]) => {
        console.log('Historiales de servicio cargados:', data);  // Aquí verifica la respuesta de la API
        this.historialServicios = data;

      },
      (error) => {
        console.log('Error al cargar historiales de servicio:', error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al cargar historiales de servicio' });
      }
    );
  }

  searchDominioIncremental(event: AutoCompleteCompleteEvent) {
    let filtered: string[] = [];
    let query = event.query;

    for (let unidadVendida of this.unidadesVendidas) {
        if (unidadVendida.dominio.toLowerCase().indexOf(query.toLowerCase()) == 0) {
            filtered.push(unidadVendida.dominio);
        }
    }

    this.dominiosCargados = filtered;
  }



  searchUnidadVendidaAndLoadAttrs(value: string | Event | AutoCompleteSelectEvent): void {
    let dominio: any;

    if (typeof value === 'string') {
      dominio = value;
    } else if (value instanceof Event) {
      dominio = (value.target as HTMLInputElement).value;
    } else if ('originalEvent' in value && 'value' in value) {
      dominio = value.value; // `value` viene del AutoCompleteSelectEvent
    }

    if (!dominio) {
      console.error('No se ha ingresado un dominio (searchUnidadVendidaAndLoadAttrs)');
      console.log('No se ha ingresado un dominio (searchUnidadVendidaAndLoadAttrs)');
      return;
    }

    console.log('Buscando unidad con dominio:', dominio);

    this.unidadesVendidasService.getUnidadVendidaByDominio(dominio).subscribe(
      (UnidadVendida: UnidadVendida) => {
        console.log('Unidad encontrada:', UnidadVendida);

        if (UnidadVendida) {
          this.selectedUnidadVendida = UnidadVendida; // Acceder al primer elemento del array
          // console.log('selectedUnidad:', this.selectedUnidad);

          // esto puede ser que lo necesite TODO por lo pronto tomaré los valores directo de la unidad vendida
          // this.historialServicioForm.get('tipoUnidad')?.setValue(this.selectedUnidadVendida?.tipoUnidad);
          // this.historialServicioForm.get('idUnidad')?.setValue(this.selectedUnidadVendida?.idUnidad);
          // this.historialServicioForm.get('nroMotor')?.setValue(this.selectedUnidadVendida?.nroMotor);

          //TODO AGREGAR EL RESTO DE LOS CAMPOS AUTOCOMPLETADOS

          // this.unidadVendidaForm.get('tipoUnidad')?.updateValueAndValidity();

        } else {
          console.warn('No se encontró ninguna unidad vendida.');
        }
      },
      (error) => {
        console.log('Error al buscar unidad vendida:', error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Unidad vendida no encontrada' });
      }
    );

  }



  nuevoRegistro(): void {
    this.isCreating = true;
    this.historialServicioForm.reset();
    this.selectedHistorialServicio = null;
    this.isEditing = true;
    this.selectedUnidadVendida = null;
    this.enableForm();

    this.historialServicioForm.get('nroMotor')?.disable();
    this.historialServicioForm.get('nroMotor')?.updateValueAndValidity();
    this.historialServicioForm.get('tipoUnidad')?.disable();
    this.historialServicioForm.get('tipoUnidad')?.updateValueAndValidity();
    this.historialServicioForm.get('idUnidad')?.disable();
    this.historialServicioForm.get('idUnidad')?.updateValueAndValidity();
  }


  //ESTA FUNCION APENAS LA EMPECE A TOCAR
  searchHistorialServicioDominio(value: string | Event | AutoCompleteSelectEvent): void {
    let dominio: any;

    if (typeof value === 'string') {
      dominio = value;
    } else if (value instanceof Event) {
      dominio = (value.target as HTMLInputElement).value;
    } else if ('originalEvent' in value && 'value' in value) {
      dominio = value.value; // `value` viene del AutoCompleteSelectEvent
    }

    // this.messageService.add({ severity: 'success', summary: 'Debug', detail: 'vin=' + vin });

    this.historialServicioService.getById(dominio, "2024-01-01").subscribe({
      next: (data: HistorialServicio) => {
        this.selectedHistorialServicio = data;
        this.historialServicioForm.patchValue(this.selectedHistorialServicio);

        console.log('selectedHistorialServicio:', this.selectedHistorialServicio);
        this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Historial de servicio encontrado' });

        this.searchUnidadVendidaAndLoadAttrs(dominio);

        this.isEditing = false;
        this.isCreating = false;
        this.disableForm();

        // Lleva al usuario al inicio de la pagina
        window.scrollTo(0, 0);
      },
      error: (err) => {
        console.error('Error al obtener historial de servicio:', err);
        // Puedes agregar manejo de errores aquí, por ejemplo:
         this.messageService.add({ severity: 'error', summary: 'Error', detail: 'No se pudo obtener el historial de servicio. Se creará uno:' });

        this.nuevoRegistro();
        this.searchUnidadVendidaAndLoadAttrs(dominio);

        // carga el dominio del formulario con el dominio de busqueda
        this.historialServicioForm.get('dominio')?.setValue(dominio);
      },
      complete: () => {
        console.log('Petición completada');
      }
    });
  }

  createHistorialServicio(): void {

    let faltanCampos=false;
    let creatingDominio = this.historialServicioForm.get('dominio')?.value;

    // Log de errores de cada control antes de guardar
    Object.keys(this.historialServicioForm.controls).forEach(key => {
      const controlErrors = this.historialServicioForm.get(key)?.errors;

      console.log('Control:', key, 'Errores:', controlErrors, 'Valor:', this.historialServicioForm.get(key)?.value);

      if (controlErrors) {
        console.log(`Errores en el campo ${key}:`, controlErrors);

        //Marca el input como ng-dirty o algo similar
        this.historialServicioForm.get(key)?.markAsDirty();
        this.historialServicioForm.get(key)?.markAsTouched();

        faltanCampos=true;
      }
    });

    if(faltanCampos){
      this.messageService.add({severity:'error', summary: 'Error', detail: 'Formulario inválido: Complete los campos obligatorios'});
      return;
    }

    if (this.historialServicioForm.valid) {

      let historialServicio = this.historialServicioForm.getRawValue() as HistorialServicio;

      historialServicio.fechaServicio = historialServicio.fechaServicio?.toISOString().split('-')[0];
      console.log('Fecha:', historialServicio.fechaServicio);

      this.historialServicioService.create(historialServicio).subscribe({
        next: () => {

          //TODO terminar de implementar la creación de historial de servicio

          // // transforma la fecha de titularidad en un objeto de tipo string en formayo yyyy-mm-dd
          // if (titular.fechaTitularidad) {
          //   const fechaTitularidad = new Date(titular.fechaTitularidad);
          //   titular.fechaTitularidad = fechaTitularidad.toISOString().split('-')[0];
          //   console.log('Fecha titularidad:', titular.fechaTitularidad);
          // }

          this.historialServicios.push(this.historialServicioForm.getRawValue() as HistorialServicio);
          this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Historial de Servicio creado exitosamente' });
        },
        error: (error) => {
          console.log('Error al crear unidad vendida:', error);
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al crear unidad vendida' });
        }
      });




      // this.isCreating = false;
    }
  }

  editarHistorialServicio(): void {
    if (this.selectedHistorialServicio) {
      this.historialServicioForm.patchValue(this.selectedHistorialServicio);
      this.isCreating = false;
    }
  }

  updateHistorialServicio(): void {
    if (this.historialServicioForm.valid && this.selectedHistorialServicio) {
      Object.assign(this.selectedHistorialServicio, this.historialServicioForm.value);
      this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Historial de Servicio actualizado exitosamente' });
      this.isCreating = false;
    }
  }

  deleteHistorialServicio(): void {
    if (this.selectedHistorialServicio) {
      this.confirmationService.confirm({
        message: '¿Está seguro de que desea eliminar este historial de servicio?',
        accept: () => {
          this.historialServicios = this.historialServicios.filter(h => h !== this.selectedHistorialServicio);
          this.selectedHistorialServicio = null;
          this.deleteSuccess = 'Historial de Servicio eliminado exitosamente';
          this.messageService.add({ severity: 'success', summary: 'Éxito', detail: this.deleteSuccess });
        },
        reject: () => {
          this.deleteError = 'Eliminación cancelada';
        }
      });
    }
  }

  cancelEdit(): void {
    this.isCreating = false;
    this.selectedHistorialServicio = null;
    this.selectedUnidadVendida = null;
    this.historialServicioForm.reset();
    this.isEditing = false;
    this.disableForm();
  }


  enableForm(): void {
    Object.keys(this.historialServicioForm.controls).forEach(controlName => {
      const control = this.historialServicioForm.get(controlName);
      console.log('Habilitando control:', controlName);
      control?.enable();
      control?.updateValueAndValidity(); // Asegúrate de actualizar la validez
    });
    console.log('Formulario habilitado y actualizado:', this.historialServicioForm);
    Object.keys(this.historialServicioForm.controls).forEach(controlName => {
      this.historialServicioForm.get(controlName)?.enable();
    });
  }

  disableForm(): void {
    Object.keys(this.historialServicioForm.controls).forEach(controlName => {
      this.historialServicioForm.get(controlName)?.disable();
    });
  }


  consultarHistorialServicio(historialServicio: HistorialServicio): void {
    this.selectedHistorialServicio = historialServicio;
    this.historialServicioForm.patchValue(historialServicio);
    this.dominioSearch = historialServicio.dominio;

    this.searchUnidadVendidaAndLoadAttrs(historialServicio.dominio);

    if (this.selectedHistorialServicio.fechaServicio) {
      const [year, month, day] = this.selectedHistorialServicio.fechaServicio.split('-').map(Number);
      console.log('Fecha:', year, month, day);
      this.selectedHistorialServicio.fechaServicioDate = new Date(year, month - 1, day);

      console.log('Fecha:', this.selectedHistorialServicio.fechaServicioDate);
      this.selectedHistorialServicio.fechaServicio = this.selectedHistorialServicio.fechaServicioDate;

      console.log('Fecha:', this.selectedHistorialServicio.fechaServicio);

    }
  }

}
