import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { HistorialServicio } from '../models/historial-servicio.model';


@Injectable({
  providedIn: 'root',
})
export class HistorialServicioService {
  private baseUrl = `${environment.baseUrl}/api/historial-servicio`;

  constructor(private http: HttpClient) {}

  // Obtener todos los historiales de servicio
  getAll(): Observable<HistorialServicio[]> {
    return this.http.get<HistorialServicio[]>(`${this.baseUrl}`);
  }

  // Obtener un historial de servicio por ID
  getById(dominio: string, fechaServicio: string): Observable<HistorialServicio> {
    return this.http.get<HistorialServicio>(`${this.baseUrl}/${dominio}/${fechaServicio}`);
  }

  // Crear un nuevo historial de servicio
  create(historial: HistorialServicio): Observable<HistorialServicio> {
    return this.http.post<HistorialServicio>(`${this.baseUrl}`, historial);
  }

  // Actualizar un historial de servicio existente
  update(id: number, historial: HistorialServicio): Observable<HistorialServicio> {
    return this.http.put<HistorialServicio>(`${this.baseUrl}/${id}`, historial);
  }

  // Eliminar un historial de servicio por ID
  delete(id: number): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/${id}`);
  }
}
