import { Component, NgZone  } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent {

  // isLoggedIn?: Observable<boolean>;
  isLoggedIn?: boolean;

  user: string = '';
  password: string = '';
  errorMessage: string | null = null;


  constructor(private authService: AuthService, private router: Router, private ngZone: NgZone) {}

  ngOnInit(): void {
    this.isLoggedIn = this.isAuthenticated();

    if (this.isLoggedIn) {
      this.ngZone.run(() => {
        this.router.navigate(['/home']);
      });
    }

  }

  login() {
    this.authService.loginCredentials(this.user, this.password).subscribe({
      next: () => {
        this.router.navigate(['/home']);
      },
      error: (error: HttpErrorResponse) => {
        if (error.status === 401) {
          this.errorMessage = 'Usuario o contraseña incorrectos.';
        } else if (error.status >= 500 || error.status === 0) {
          this.errorMessage = 'No se pudo conectar al servidor. Inténtelo de nuevo más tarde.';
        } else {
          this.errorMessage = 'Ocurrió un error inesperado. Inténtelo de nuevo.';
        }
      }
    });
  }

  loginGoogleLocal() {
    this.authService.loginGoogleLocal();

    // Navegar a la página de inicio
      this.ngZone.run(() => {
        this.router.navigate(['/home']);
      });
  }


  logout() {
    this.authService.logout();
  }

  isAuthenticated() {
    return this.authService.isAuthenticated();
  }

}
