import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CatalogoService {
    private baseUrl: string = environment.baseUrl + '/api/catalogo';


  constructor(private http: HttpClient) {}

  /**
   * Obtener un ítem del catálogo por ID
   * @param tipoUnidad Tipo de unidad
   * @param idUnidad ID de la unidad
   * @returns Observable con el ítem del catálogo
   */
  getItemByTipoUnidad(tipoUnidad: string): Observable<any> {
    return this.http.get(`${this.baseUrl}/tipo/${tipoUnidad}`);
  }

  /**
   * Obtener todos los ítems del catálogo
   * @returns Observable con la lista de ítems del catálogo
   */
  getAllItems(): Observable<any[]> {
    return this.http.get<any[]>(this.baseUrl);
  }

  /**
   * Crear un nuevo ítem en el catálogo
   * @param tipoUnidad Tipo de unidad
   * @param idUnidad ID de la unidad
   * @param data Datos del nuevo ítem
   * @returns Observable con la respuesta de la creación
   */
  createItem(tipoUnidad: string, idUnidad: number, data: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/${tipoUnidad}/${idUnidad}`, data);
  }

  /**
   * Actualizar un ítem existente en el catálogo
   * @param tipoUnidad Tipo de unidad
   * @param idUnidad ID de la unidad
   * @param data Datos actualizados del ítem
   * @returns Observable con la respuesta de la actualización
   */
  updateItem(tipoUnidad: string, idUnidad: number, data: any): Observable<any> {
    return this.http.put(`${this.baseUrl}/${tipoUnidad}/${idUnidad}`, data);
  }

  /**
   * Eliminar un ítem del catálogo
   * @param tipoUnidad Tipo de unidad
   * @param idUnidad ID de la unidad
   * @returns Observable con la respuesta de la eliminación
   */
  deleteItem(tipoUnidad: string, idUnidad: number): Observable<any> {
    return this.http.delete(`${this.baseUrl}/${tipoUnidad}/${idUnidad}`);
  }
}
