import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BillOfLanding } from '../models/bill-of-landing.model';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BillOfLandingService {
  private baseUrl: string = `${environment.baseUrl}/api/bill-of-landing`;

  constructor(private http: HttpClient) {}

  getAll(): Observable<BillOfLanding[]> {
    return this.http.get<BillOfLanding[]>(this.baseUrl).pipe(
      catchError(this.handleError)
    );
  }

  getById(id: string): Observable<BillOfLanding> { // Cambiado a string para coincidir con idBl
    return this.http.get<BillOfLanding>(`${this.baseUrl}/${id}`).pipe(
      catchError(this.handleError)
    );
  }

  create(billOfLanding: BillOfLanding): Observable<BillOfLanding> {
    return this.http.post<BillOfLanding>(this.baseUrl, billOfLanding).pipe(
      catchError(this.handleError)
    );
  }

  getBillOfLandingsByEmpaqueId(id: string): Observable<BillOfLanding[]> {
    return this.http.get<BillOfLanding[]>(`${this.baseUrl}/empaque/${id}`);
  }

  update(id: string, billOfLanding: BillOfLanding): Observable<BillOfLanding> { // Cambiado a string para coincidir con idBl
    return this.http.put<BillOfLanding>(`${this.baseUrl}/${id}`, billOfLanding).pipe(
      catchError(this.handleError)
    );
  }

  delete(id: string): Observable<void> { // Cambiado a string para coincidir con idBl
    return this.http.delete<void>(`${this.baseUrl}/${id}`).pipe(
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse) {
    console.error('Error en la comunicación con el servidor:', error);
    return throwError('Error en la comunicación con el servidor.');
  }
}
