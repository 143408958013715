import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { Agencia } from '../models/agencia.model';

@Injectable({
  providedIn: 'root'
})
export class AgenciaService {
  private baseUrl: string = environment.baseUrl + '/api/agencias';

  constructor(private http: HttpClient) {}

  getAll(): Observable<Agencia[]> {
    return this.http.get<Agencia[]>(this.baseUrl);
  }
}
