// Service: SolicitudesService
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment'; // Ajusta
import { Solicitud } from '../models/solicitud.model'; // Ajusta la ruta según tu estructura

@Injectable({
  providedIn: 'root',
})
export class SolicitudesService {
  private apiUrl = `${environment.baseUrl}/api/solicitudes`;

  constructor(private http: HttpClient) {}

  // Obtener todas las solicitudes
  getAllSolicitudes(): Observable<Solicitud[]> {
    return this.http.get<Solicitud[]>(this.apiUrl);
  }

  // Crear una nueva solicitud
  createSolicitud(solicitud: Solicitud): Observable<Solicitud> {
    return this.http.post<Solicitud>(this.apiUrl, solicitud);
  }

  // Obtener una solicitud por ID
  getSolicitudById(id: number): Observable<Solicitud> {
    return this.http.get<Solicitud>(`${this.apiUrl}/${id}`);
  }

  // Actualizar una solicitud existente
  updateSolicitud(id: number, solicitud: Solicitud): Observable<Solicitud> {
    return this.http.put<Solicitud>(`${this.apiUrl}/${id}`, solicitud);
  }

  // Eliminar una solicitud
  deleteSolicitud(id: number): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/${id}`);
  }
}
