import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { routes } from './app.routes';
import { MenubarModule } from 'primeng/menubar';
import { CommonModule } from '@angular/common';
import { UnidadesModule } from './components/unidades/unidades.module';
import { FormsModule } from '@angular/forms';
import { LoginComponent } from './auth/login/login.component';
import { CallbackComponent } from './callback/callback.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './services/auth.interceptor';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CatalogosComponent } from './components/catalogo/catalogo/catalogo.component';
import { AgenciasComponent } from './components/catalogo/agencias/agencias.component';
import { CardModule } from 'primeng/card';
import { ConfirmDialog, ConfirmDialogModule } from 'primeng/confirmdialog';
import { TableModule } from 'primeng/table';
import { ReactiveFormsModule } from '@angular/forms';
import { UnidadesVendidasComponent } from './components/unidades-vendidas/unidades-vendidas.component';
import { HistorialServicioComponent } from './components/historial-servicio/historial-servicio.component';
import { DropdownModule } from 'primeng/dropdown';
import { TiposUnidadComponent } from './components/tipos-unidad/tipos-unidad.component';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';

@NgModule({
  declarations: [AppComponent,
    HistorialServicioComponent,
    LoginComponent,
    CallbackComponent,
    AgenciasComponent,
    CatalogosComponent,
    TiposUnidadComponent,
    UnidadesVendidasComponent], // Declara el componente raíz
  imports: [BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    RouterModule,
    MenubarModule,
    TableModule,
    CommonModule,
    CalendarModule,
    DropdownModule,
    ConfirmDialogModule,
    HttpClientModule,
    CardModule,
    FormsModule,
    AutoCompleteModule,
    RouterModule.forRoot(routes),
    ReactiveFormsModule,
    UnidadesModule,
    ButtonModule,
    ToastModule
  ],     // Importa módulos necesarios
  providers: [ {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true
  }, MessageService
],
  bootstrap: [AppComponent],    // Especifica el componente raíz
})
export class AppModule {}
//
