import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { TiposUnidad } from '../../models/tipos-unidad.model';

@Component({
  selector: 'app-tipos-unidad',
  templateUrl: './tipos-unidad.component.html',
  styleUrls: ['./tipos-unidad.component.scss'],
  providers: [ConfirmationService, MessageService]
})
export class TiposUnidadComponent implements OnInit {
  tiposUnidad: TiposUnidad[] = [];
  selectedTipoUnidad: TiposUnidad | null = null;
  tipoUnidadForm: FormGroup;
  isCreating: boolean = false;
  isEditing: boolean = false;
  deleteError: string | null = null;
  deleteSuccess: string | null = null;
  tipoUnidadSearch: string = '';

  constructor(
    private fb: FormBuilder,
    private confirmationService: ConfirmationService,
    private messageService: MessageService
  ) {
    this.tipoUnidadForm = this.fb.group({
      createdBy: ['', [Validators.required]],
      createdAt: [null, [Validators.required]],
      updatedBy: [''],
      updatedAt: [null],
      operacion: ['', [Validators.required]],
      tipoUnidad: ['', [Validators.required]],
      descripcion: ['', [Validators.required]]
    });
  }

  ngOnInit(): void {
    // Inicializar datos de tipos de unidad aquí
  }

  searchTipoUnidad(): void {
    // Implementar lógica de búsqueda aquí
  }

  nuevoRegistro(): void {
    this.isCreating = true;
    this.isEditing = false;
    this.tipoUnidadForm.reset();
    this.selectedTipoUnidad = null;
  }

  createTipoUnidad(): void {
    if (this.tipoUnidadForm.valid) {
      const newTipoUnidad = this.tipoUnidadForm.value as TiposUnidad;
      this.tiposUnidad.push(newTipoUnidad);
      this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Tipo de Unidad creado exitosamente' });
      this.isCreating = false;
    }
  }

  editarTipoUnidad(): void {
    if (this.selectedTipoUnidad) {
      this.tipoUnidadForm.patchValue(this.selectedTipoUnidad);
      this.isCreating = false;
      this.isEditing = true;
    }
  }

  updateTipoUnidad(): void {
    if (this.tipoUnidadForm.valid && this.selectedTipoUnidad) {
      Object.assign(this.selectedTipoUnidad, this.tipoUnidadForm.value);
      this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Tipo de Unidad actualizado exitosamente' });
      this.isEditing = false;
    }
  }

  deleteTipoUnidad(): void {
    if (this.selectedTipoUnidad) {
      this.confirmationService.confirm({
        message: '¿Está seguro de que desea eliminar este tipo de unidad?',
        accept: () => {
          this.tiposUnidad = this.tiposUnidad.filter(t => t !== this.selectedTipoUnidad);
          this.selectedTipoUnidad = null;
          this.deleteSuccess = 'Tipo de Unidad eliminado exitosamente';
          this.messageService.add({ severity: 'success', summary: 'Éxito', detail: this.deleteSuccess });
        },
        reject: () => {
          this.deleteError = 'Eliminación cancelada';
        }
      });
    }
  }

  cancelEdit(): void {
    this.isCreating = false;
    this.isEditing = false;
    this.selectedTipoUnidad = null;
    this.tipoUnidadForm.reset();
  }

  consultarTipoUnidad(tipoUnidad: TiposUnidad): void {
    this.selectedTipoUnidad = tipoUnidad;
    this.tipoUnidadForm.patchValue(tipoUnidad);
  }
}
