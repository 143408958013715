<p-toast position="top-center"></p-toast>
<p-confirmDialog></p-confirmDialog>

<!-- Búsqueda de Agencia -->
<p-card class=""  [style]="{ 'margin-top': '0px', 'padding': '0px', 'margin-right': '16px', 'margin-left': '16px', 'margin-bottom': '8px', 'background-color': 'transparent !important' }">
  <div class="flex gap-4 items-center">
    <input pInputText [(ngModel)]="nombreSearch" class="p-inputtext p-component w-1/2 p-2 mb-4 border rounded-lg" placeholder="Buscar por Nombre" />
    <button pButton icon="pi pi-search" label="Buscar" (click)="searchAgencia()" class="p-button-primary mb-4"></button>
  </div>

  <!-- Fila de botones: Nuevo, Eliminar, Editar -->
  <div class="flex gap-2">
    <button pButton icon="pi pi-plus" label="Nuevo" (click)="nuevoRegistro()" class="p-button-success"></button>
    <button pButton icon="pi pi-trash" label="Eliminar" (click)="deleteAgencia()" class="p-button-danger" [disabled]="!selectedAgencia"></button>
    <button pButton icon="pi pi-pencil" label="Editar" (click)="editarAgencia()" class="p-button-warning" [disabled]="!selectedAgencia"></button>

    <!-- Mensaje de error para la eliminación de una Agencia -->
    <div *ngIf="deleteError" class="text-red-600">
      <small>{{ deleteError }}</small>
    </div>

    <!-- Mensaje de éxito para la eliminación de una Agencia -->
    <div *ngIf="deleteSuccess" class="text-green-600">
      <small>{{ deleteSuccess }}</small>
    </div>
  </div>
</p-card>

<!-- Formulario para crear o editar una Agencia -->
<p-card class="" [style]="{ 'padding-top': '0px', 'padding': '0px', 'margin': '16px' }">
  <form [formGroup]="agenciaForm" (ngSubmit)="isCreating ? createAgencia() : updateAgencia()">
    <div class="mx-auto rounded-3xl w-full">
      <h1 class="text-4xl font-semibold mb-4">Maestro de Agencias</h1>
      <div class="rounded p-0 mt-0 mb-8">
        <h2 class="text-2xl font-semibold mb-2">Datos identificatorios</h2>
        <div class="grid grid-cols-4 gap-4 mb-6">
          <div class="p-field">
            <label for="nombre" class="block text-gray-700 font-semibold">Nombre *</label>
            <input id="nombre" formControlName="nombre" pInputText class="p-inputtext w-full p-2 border rounded-lg" />
            <div *ngIf="agenciaForm.get('nombre')?.invalid && agenciaForm.get('nombre')?.touched">
              <small class="text-red-600" *ngIf="agenciaForm.get('nombre')?.errors?.['required']">
                El Nombre es requerido
              </small>
            </div>
          </div>
          <div class="p-field">
            <label for="cuitCuil" class="block text-gray-700 font-semibold">CUIT/CUIL *</label>
            <input id="cuitCuil" formControlName="cuitCuil" pInputText class="w-full p-2 border rounded-lg" />
            <div *ngIf="agenciaForm.get('cuitCuil')?.invalid && agenciaForm.get('cuitCuil')?.touched">
              <small class="text-red-600" *ngIf="agenciaForm.get('cuitCuil')?.errors?.['required']">
                El CUIT/CUIL es requerido
              </small>
            </div>
          </div>
          <div class="p-field">
            <label for="movil" class="block text-gray-700 font-semibold">Móvil</label>
            <input id="movil" formControlName="movil" pInputText class="w-full p-2 border rounded-lg" />
          </div>
          <div class="p-field">
            <label for="correo" class="block text-gray-700 font-semibold">Correo</label>
            <input id="correo" formControlName="correo" pInputText class="w-full p-2 border rounded-lg" />
          </div>
          <div class="p-field">
            <label for="calle" class="block text-gray-700 font-semibold">Calle</label>
            <input id="calle" formControlName="calle" pInputText class="w-full p-2 border rounded-lg" />
          </div>
          <div class="p-field">
            <label for="numero" class="block text-gray-700 font-semibold">Número</label>
            <input id="numero" formControlName="numero" pInputText class="w-full p-2 border rounded-lg" />
          </div>
          <div class="p-field">
            <label for="provincia" class="block text-gray-700 font-semibold">Provincia</label>
            <input id="provincia" formControlName="provincia" pInputText class="w-full p-2 border rounded-lg" />
          </div>
          <div class="p-field">
            <label for="localidad" class="block text-gray-700 font-semibold">Localidad</label>
            <input id="localidad" formControlName="localidad" pInputText class="w-full p-2 border rounded-lg" />
          </div>
          <div class="p-field">
            <label for="codPostal" class="block text-gray-700 font-semibold">Código Postal</label>
            <input id="codPostal" formControlName="codPostal" pInputText class="w-full p-2 border rounded-lg" />
          </div>
          <div class="p-field">
            <label for="ponderacion" class="block text-gray-700 font-semibold">Ponderación</label>
            <input id="ponderacion" formControlName="ponderacion" pInputText class="w-full p-2 border rounded-lg" />
          </div>
          <div class="p-field col-span-2">
            <label for="comentarios" class="block text-gray-700 font-semibold">Comentarios</label>
            <textarea id="comentarios" formControlName="comentarios" pInputTextarea class="w-full p-2 border rounded-lg"></textarea>
          </div>
        </div>
      </div>

      <div class="flex gap-2">
        <button pButton type="submit" class="p-2 btn btn-danger" [disabled]="!isEditing && !isCreating">Guardar</button>
        <button pButton (click)="cancelEdit()" class="p-2 p-button-primary btn btn-danger" [disabled]="!isEditing && !isCreating">Cancelar</button>
      </div>
    </div>
  </form>
</p-card>

<p-card class="" [style]="{ 'padding-top': '0px', 'padding': '0px', 'margin': '16px' }">
  <!-- Tabla de agencias -->
  <p-table [value]="agencias" styleClass="p-datatable-striped" [scrollable]="true">
    <ng-template pTemplate="caption">
      <div class="flex align-items-center justify-content-between">
        Lista de Agencias
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th>Nombre</th>
        <th>CUIT/CUIL</th>
        <th>Correo</th>
        <th>Localidad</th>
        <th>Consultar</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-agencia>
      <tr>
        <td>{{ agencia.nombre }}</td>
        <td>{{ agencia.cuitCuil }}</td>
        <td>{{ agencia.correo }}</td>
        <td>{{ agencia.localidad }}</td>
        <td>
          <button pButton icon="pi pi-search" (click)="consultarAgencia(agencia)" class="p-button-primary"></button>
        </td>
      </tr>
    </ng-template>
  </p-table>
</p-card>
