import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Empaque } from '../models/empaque.model';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EmpaqueService {
  private baseUrl: string = `${environment.baseUrl}/api/empaques`;

  constructor(private http: HttpClient) {}

  getAll(): Observable<Empaque[]> {
    return this.http.get<Empaque[]>(this.baseUrl).pipe(
      catchError(this.handleError)
    );
  }

  getById(id: number): Observable<Empaque> {
    return this.http.get<Empaque>(`${this.baseUrl}/${id}`).pipe(
      catchError(this.handleError)
    );
  }

  create(empaque: Empaque): Observable<Empaque> {
    return this.http.post<Empaque>(this.baseUrl, empaque).pipe(
      catchError(this.handleError)
    );
  }

  update(id: number, empaque: Empaque): Observable<Empaque> {
    return this.http.put<Empaque>(`${this.baseUrl}/${id}`, empaque).pipe(
      catchError(this.handleError)
    );
  }

  delete(id: number): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/${id}`).pipe(
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse) {
    console.error('Error en la comunicación con el servidor:', error);
    return throwError('Error en la comunicación con el servidor.');
  }
}
