// Service: MonedasService
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { Moneda } from '../models/moneda.model';


@Injectable({
  providedIn: 'root',
})
export class MonedasService {
  private apiUrl = `${environment.baseUrl}/api/monedas`;

  constructor(private http: HttpClient) {}

  // Obtener todas las monedas
  getAllMonedas(): Observable<Moneda[]> {
    return this.http.get<Moneda[]>(this.apiUrl);
  }
}


