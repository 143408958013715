<p-toast position="top-center"></p-toast>
<p-confirmDialog></p-confirmDialog>

<!-- Búsqueda de Catálogo -->
<p-card class="" [style]="{ 'margin-top': '0px', 'padding': '0px', 'margin-right': '16px', 'margin-left': '16px', 'margin-bottom': '8px', 'background-color': 'transparent !important' }">
  <div class="flex gap-4 items-center">
    <input pInputText [(ngModel)]="searchText" class="p-inputtext p-component w-1/2 p-2 mb-4 border rounded-lg" placeholder="Buscar por Tipo de Unidad" />
    <button pButton icon="pi pi-search" label="Buscar" (click)="buscarCatalogo()" class="p-button-primary mb-4"></button>
  </div>

  <!-- Fila de botones: Nuevo, Eliminar, Editar -->
  <div class="flex gap-2">
    <button pButton icon="pi pi-plus" label="Nuevo" (click)="nuevoCatalogo()" class="p-button-success"></button>
    <button pButton icon="pi pi-trash" label="Eliminar" (click)="eliminarCatalogo()" class="p-button-danger" [disabled]="!selectedCatalogo"></button>
    <button pButton icon="pi pi-pencil" label="Editar" (click)="editarCatalogo()" class="p-button-warning" [disabled]="!selectedCatalogo"></button>

    <!-- Mensaje de error para la eliminación de un Catálogo -->
    <div *ngIf="deleteError" class="text-red-600">
      <small>{{ deleteError }}</small>
    </div>

    <!-- Mensaje de éxito para la eliminación de un Catálogo -->
    <div *ngIf="deleteSuccess" class="text-green-600">
      <small>{{ deleteSuccess }}</small>
    </div>
  </div>
</p-card>

<!-- Formulario para crear o editar un Catálogo -->
<p-card class="" [style]="{ 'padding-top': '0px', 'padding': '0px', 'margin': '16px' }">
  <form [formGroup]="catalogoForm" (ngSubmit)="isCreating ? createCatalogo() : updateCatalogo()">
    <div class="mx-auto rounded-3xl w-full">
      <h1 class="text-4xl font-semibold mb-4">Maestro de Catálogos</h1>
      <div class="rounded p-0 mt-0 mb-8">
        <h2 class="text-2xl font-semibold mb-2">Datos identificatorios</h2>
        <div class="grid grid-cols-4 gap-4 mb-6">
          <div class="p-field">
            <label for="tipoUnidad" class="block text-gray-700 font-semibold">Tipo de Unidad *</label>
            <input id="tipoUnidad" formControlName="tipoUnidad" pInputText class="p-inputtext w-full p-2 border rounded-lg" />
            <div *ngIf="catalogoForm.get('tipoUnidad')?.invalid && catalogoForm.get('tipoUnidad')?.touched">
              <small class="text-red-600" *ngIf="catalogoForm.get('tipoUnidad')?.errors?.['required']">
                El Tipo de Unidad es requerido
              </small>
            </div>
          </div>
          <div class="p-field">
            <label for="idUnidad" class="block text-gray-700 font-semibold">ID Unidad *</label>
            <input id="idUnidad" formControlName="idUnidad" pInputText class="w-full p-2 border rounded-lg" />
            <div *ngIf="catalogoForm.get('idUnidad')?.invalid && catalogoForm.get('idUnidad')?.touched">
              <small class="text-red-600" *ngIf="catalogoForm.get('idUnidad')?.errors?.['required']">
                El ID Unidad es requerido
              </small>
            </div>
          </div>
          <div class="p-field">
            <label for="nombre" class="block text-gray-700 font-semibold">Nombre *</label>
            <input id="nombre" formControlName="nombre" pInputText class="w-full p-2 border rounded-lg" />
            <div *ngIf="catalogoForm.get('nombre')?.invalid && catalogoForm.get('nombre')?.touched">
              <small class="text-red-600" *ngIf="catalogoForm.get('nombre')?.errors?.['required']">
                El Nombre es requerido
              </small>
            </div>
          </div>
          <div class="p-field">
            <label for="nombreChino" class="block text-gray-700 font-semibold">Nombre Chino</label>
            <input id="nombreChino" formControlName="nombreChino" pInputText class="w-full p-2 border rounded-lg" />
          </div>
          <div class="p-field">
            <label for="nombreIngles" class="block text-gray-700 font-semibold">Nombre Inglés</label>
            <input id="nombreIngles" formControlName="nombreIngles" pInputText class="w-full p-2 border rounded-lg" />
          </div>
        </div>
      </div>

      <div class="border border-gray-300 rounded p-2 mb-8">
        <h2 class="text-2xl font-semibold mb-4">Detalles</h2>
        <div class="grid grid-cols-3 gap-4 mb-6">
          <div class="p-field">
            <label for="unidadesEmbalaje" class="block text-gray-700 font-semibold">Unidades de Embalaje *</label>
            <input id="unidadesEmbalaje" formControlName="unidadesEmbalaje" pInputText class="w-full p-2 border rounded-lg" />
            <div *ngIf="catalogoForm.get('unidadesEmbalaje')?.invalid && catalogoForm.get('unidadesEmbalaje')?.touched">
              <small class="text-red-600">
                Las Unidades de Embalaje son requeridas
              </small>
            </div>
          </div>
          <div class="p-field">
            <label for="stockMinimoRecomendado" class="block text-gray-700 font-semibold">Stock Mínimo Recomendado *</label>
            <input id="stockMinimoRecomendado" formControlName="stockMinimoRecomendado" pInputText class="w-full p-2 border rounded-lg" />
            <div *ngIf="catalogoForm.get('stockMinimoRecomendado')?.invalid && catalogoForm.get('stockMinimoRecomendado')?.touched">
              <small class="text-red-600">
                El Stock Mínimo Recomendado es requerido
              </small>
            </div>
          </div>
          <div class="p-field">
            <label for="valorRef" class="block text-gray-700 font-semibold">Valor Referencial *</label>
            <input id="valorRef" formControlName="valorRef" pInputText class="w-full p-2 border rounded-lg" />
            <div *ngIf="catalogoForm.get('valorRef')?.invalid && catalogoForm.get('valorRef')?.touched">
              <small class="text-red-600">
                El Valor Referencial es requerido
              </small>
            </div>
          </div>
          <div class="p-field">
            <label for="idMoneda" class="block text-gray-700 font-semibold">ID Moneda *</label>
            <input id="idMoneda" formControlName="idMoneda" pInputText class="w-full p-2 border rounded-lg" />
            <div *ngIf="catalogoForm.get('idMoneda')?.invalid && catalogoForm.get('idMoneda')?.touched">
              <small class="text-red-600">
                El ID Moneda es requerido
              </small>
            </div>
          </div>
          <div class="p-field">
            <label for="fechaRef" class="block text-gray-700 font-semibold">Fecha Referencial *</label>
            <input id="fechaRef" formControlName="fechaRef" pInputText class="w-full p-2 border rounded-lg" />
            <div *ngIf="catalogoForm.get('fechaRef')?.invalid && catalogoForm.get('fechaRef')?.touched">
              <small class="text-red-600">
                La Fecha Referencial es requerida
              </small>
            </div>
          </div>
        </div>
      </div>

      <div class="border border-gray-300 rounded p-2 mb-8">
        <h2 class="text-2xl font-semibold mb-4">Sección</h2>
        <div class="grid grid-cols-2 gap-4 mb-6">
          <div class="p-field">
            <label for="idSeccion" class="block text-gray-700 font-semibold">ID Sección *</label>
            <input id="idSeccion" formControlName="idSeccion" pInputText class="w-full p-2 border rounded-lg" />
            <div *ngIf="catalogoForm.get('idSeccion')?.invalid && catalogoForm.get('idSeccion')?.touched">
              <small class="text-red-600">
                El ID Sección es requerido
              </small>
            </div>
          </div>
          <div class="p-field">
            <label for="idSubseccion" class="block text-gray-700 font-semibold">ID Subsección *</label>
            <input id="idSubseccion" formControlName="idSubseccion" pInputText class="w-full p-2 border rounded-lg" />
            <div *ngIf="catalogoForm.get('idSubseccion')?.invalid && catalogoForm.get('idSubseccion')?.touched">
              <small class="text-red-600">
                El ID Subsección es requerido
              </small>
            </div>
          </div>
          <div class="p-field">
            <label for="nombreSeccion" class="block text-gray-700 font-semibold">Nombre Sección *</label>
            <input id="nombreSeccion" formControlName="nombreSeccion" pInputText class="w-full p-2 border rounded-lg" />
            <div *ngIf="catalogoForm.get('nombreSeccion')?.invalid && catalogoForm.get('nombreSeccion')?.touched">
              <small class="text-red-600">
                El Nombre Sección es requerido
              </small>
            </div>
          </div>
          <div class="p-field">
            <label for="nombreSubseccion" class="block text-gray-700 font-semibold">Nombre Subsección *</label>
            <input id="nombreSubseccion" formControlName="nombreSubseccion" pInputText class="w-full p-2 border rounded-lg" />
            <div *ngIf="catalogoForm.get('nombreSubseccion')?.invalid && catalogoForm.get('nombreSubseccion')?.touched">
              <small class="text-red-600">
                El Nombre Subsección es requerido
              </small>
            </div>
          </div>
        </div>
      </div>

      <div class="flex gap-2">
        <button pButton type="submit" class="p-2 btn btn-danger" [disabled]="!isEditing && !isCreating">Guardar</button>
        <button pButton (click)="cancelEdit()" class="p-2 p-button-primary btn btn-danger" [disabled]="!isEditing && !isCreating">Cancelar</button>
      </div>
    </div>
  </form>
</p-card>

<p-card class="" [style]="{ 'padding-top': '0px', 'padding': '0px', 'margin': '16px' }">
  <!-- Tabla de catálogos -->
  <p-table [value]="catalogos" styleClass="p-datatable-striped" [scrollable]="true">
    <ng-template pTemplate="caption">
      <div class="flex align-items-center justify-content-between">
        Lista de Catálogos
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th>Tipo de Unidad</th>
        <th>ID Unidad</th>
        <th>Nombre</th>
        <th>Nombre Chino</th>
        <th>Nombre Inglés</th>
        <th>Consultar</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-catalogo>
      <tr>
        <td>{{ catalogo.tipoUnidad }}</td>
        <td>{{ catalogo.idUnidad }}</td>
        <td>{{ catalogo.nombre }}</td>
        <td>{{ catalogo.nombreChino }}</td>
        <td>{{ catalogo.nombreIngles }}</td>
        <td>
          <button pButton icon="pi pi-search" (click)="consultarCatalogo()" class="p-button-primary"></button>
        </td>
      </tr>
    </ng-template>
  </p-table>
</p-card>
