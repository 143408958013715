<p-toast position="top-center"></p-toast>
<p-confirmDialog></p-confirmDialog>

    <p-card class="" [style]="{ 'margin-top': '0px', 'padding': '0px', 'margin-right': '16px', 'margin-left': '16px', 'margin-bottom': '8px', 'background-color': 'transparent !important' }">
      <div class="flex gap-4 items-center">
        <p-autoComplete [(ngModel)]="dominioSearch" [suggestions]="dominiosCargados"
        [showClear]="true"
        [minLength]="1" placeholder="Buscar por dominio"  class="p-component w-1/2 mb-4 rounded-lg"
          (completeMethod)="searchDominioIncremental($event)"
          (onSelect)="searchHistorialServicioDominio($event)" [dropdown]="true"  [style]="{ 'width': '100%' }"
          >
        </p-autoComplete>

        <button pButton icon="pi pi-search" label="Buscar" (click)="searchHistorialServicioDominio(dominioSearch)" class="p-button-primary mb-4"></button>
      </div>

      <!-- Fila de botones: Nuevo, Eliminar, Editar -->
      <div class="flex gap-4 items-center">
        <button pButton icon="pi pi-plus" label="Nuevo" (click)="nuevoRegistro()" class="p-button-success"></button>
        <button pButton icon="pi pi-trash" label="Eliminar" (click)="deleteHistorialServicio()" class="p-button-danger" [disabled]="!selectedHistorialServicio"></button>
        <button pButton icon="pi pi-pencil" label="Editar" (click)="editarHistorialServicio()" class="p-button-warning" [disabled]="!selectedHistorialServicio"></button>

        <!-- Mensaje de error para la eliminación de una Unidad Vendida -->
        <div *ngIf="deleteError" class="text-red-600">
          <small>{{ deleteError }}</small>
        </div>

        <!-- Mensaje de éxito para la eliminación de una Unidad Vendida -->
        <div *ngIf="deleteSuccess" class="text-green-600">
          <small>{{ deleteSuccess }}</small>
        </div>
      </div>
    </p-card>


<!-- Formulario para crear o editar un Historial de Servicio -->
<p-card class="" [style]="{ 'padding-top': '0px', 'padding': '0px', 'margin': '16px' }">
  <form [formGroup]="historialServicioForm" (ngSubmit)="isCreating ? createHistorialServicio() : updateHistorialServicio()">
    <div class="mx-auto rounded-3xl w-full">
      <h1 class="text-4xl font-semibold mb-4">Historial de Servicio</h1>
      <div class="rounded p-0 mt-0 mb-8">
        <h2 class="text-2xl font-semibold mb-2">Datos identificatorios</h2>
          <div class="grid grid-cols-4 gap-4 mb-6">
            <div class="p-field">
              <label for="vin" class="block text-white font-semibold">VIN *</label>
              <input id="vin"    [value]="selectedUnidadVendida?.vin"   pInputText class="p-element p-component w-full p-2 rounded-lg" [disabled]="true">
            </div>
            <div class="p-field">
              <label for="nroMotor" class="block text-white font-semibold">Número de Motor *</label>
              <!-- formControlName="nroMotor" -->
              <input id="nroMotor"   [value]="selectedUnidadVendida?.nroMotor"   pInputText class="p-element p-component w-full p-2 rounded-lg" [disabled]="true" />
            </div>
            <div class="p-field">
              <label for="tipoUnidad" class="block text-white font-semibold">Tipo de Unidad *</label>
              <!-- formControlName="tipoUnidad" -->
              <input id="tipoUnidad"  [value]="selectedUnidadVendida?.tipoUnidad"   pInputText class=" p-element p-component w-full p-2 border rounded-lg" [disabled]="true" />
            </div>
            <div class="p-field">
              <label for="idUnidad" class="block text-white font-semibold">ID Unidad *</label>
              <!-- formControlName="idUnidad" -->
              <input id="idUnidad"   [value]="selectedUnidadVendida?.idUnidad"   pInputText class=" p-element p-component w-full p-2 border rounded-lg" [disabled]="true" />
            </div>

          <!-- <div class="p-field">
            <label for="dominio" class="block text-white font-semibold">Dominio *</label>
            <input id="dominio" formControlName="dominio" pInputText class="p-inputtext w-full p-2 border rounded-lg" />
          </div> -->
        </div>
      </div>

      <div class="border border-gray-300 rounded p-2 mb-8">
        <h2 class="text-2xl font-semibold mb-4">Detalles</h2>
        <div class="grid grid-cols-4 gap-4 mb-6">
          <div class="p-field">
            <label for="fechaServicio" class="block text-white font-semibold">Fecha de Servicio *</label>
            <p-calendar id="fechaServicio" [readonlyInput]="!isEditing && !isCreating" formControlName="fechaServicio" [style]="{ minWidth: '50px', width: '50%' }" inputStyleClass="w-full p-2 border rounded-lg" />
            <div *ngIf="historialServicioForm.get('fechaServicio')?.invalid && historialServicioForm.get('fechaServicio')?.touched">
              <small class="text-red-600" *ngIf="historialServicioForm.get('fechaServicio')?.errors?.['required']">
                La Fecha de Servicio es requerida
              </small>
            </div>
          </div>
          <div class="p-field">
            <label for="idAgencia" class="block text-white font-semibold">ID Agencia *</label>
            <!-- <input id="idAgencia" formControlName="idAgencia" pInputText class="w-full p-2 border rounded-lg" /> -->

            <p-dropdown id="idAgencia" formControlName="idAgencia"
            class="w-full p-dropdown-sm p-2"
            [ngClass]="{'p-invalid': historialServicioForm.get('idAgencia')?.invalid && (historialServicioForm.get('idAgencia')?.dirty || historialServicioForm.get('idAgencia')?.touched)}"
            [showClear]="true"
            [filter]="true"
            filterPlaceholder="Buscar Agencia"
            [options]="agencias" autoWidth="false"
            [style]="{ width: '15rem',  height: '2.6rem' }"></p-dropdown>
            <div *ngIf="historialServicioForm.get('idAgencia')?.invalid && historialServicioForm.get('idAgencia')?.touched">
              <small class="text-red-600">
                El ID Agencia es requerido
              </small>
            </div>


            <div *ngIf="historialServicioForm.get('idAgencia')?.invalid && historialServicioForm.get('idAgencia')?.touched">
              <small class="text-red-600" *ngIf="historialServicioForm.get('idAgencia')?.errors?.['required']">
                El ID Agencia es requerido
              </small>
            </div>
          </div>
          <div class="p-field">
            <label for="kmIngreso" class="block text-white font-semibold">Kilómetros de Ingreso *</label>
            <input id="kmIngreso" formControlName="kmIngreso" pInputText class="p-element p-component w-full p-2 border rounded-lg" />
            <div *ngIf="historialServicioForm.get('kmIngreso')?.invalid && historialServicioForm.get('kmIngreso')?.touched">
              <small class="text-red-600" *ngIf="historialServicioForm.get('kmIngreso')?.errors?.['required']">
                Los Kilómetros de Ingreso son requeridos
              </small>
            </div>
          </div>
          <div class="p-field">
            <label for="garantia" class="block text-white font-semibold">Garantía *</label>
            <p-dropdown id="garantia" formControlName="garantia"  [options]="garantiaOptions"  class="w-full p-dropdown-sm p-2"></p-dropdown>
            <div *ngIf="historialServicioForm.get('garantia')?.invalid && historialServicioForm.get('garantia')?.touched">
              <small class="text-red-600" *ngIf="historialServicioForm.get('garantia')?.errors?.['required']">
                La Garantía es requerida
              </small>
            </div>
          </div>
          <div class="p-field">
            <label for="idServicio" class="block text-white font-semibold">ID Servicio *</label>
            <input id="idServicio" formControlName="idServicio" pInputText class="w-full p-2 border rounded-lg" />
            <div *ngIf="historialServicioForm.get('idServicio')?.invalid && historialServicioForm.get('idServicio')?.touched">
              <small class="text-red-600" *ngIf="historialServicioForm.get('idServicio')?.errors?.['required']">
                El ID Servicio es requerido
              </small>
            </div>
          </div>
          <div class="p-field">
            <label for="idTarea" class="block text-white font-semibold">ID Tarea *</label>
            <input id="idTarea" formControlName="idTarea" pInputText class="w-full p-2 border rounded-lg" />
            <div *ngIf="historialServicioForm.get('idTarea')?.invalid && historialServicioForm.get('idTarea')?.touched">
              <small class="text-red-600" *ngIf="historialServicioForm.get('idTarea')?.errors?.['required']">
                El ID Tarea es requerido
              </small>
            </div>
          </div>
          <div class="p-field col-span-2">
            <label for="comentarios" class="block text-white font-semibold ">Comentarios</label>
            <textarea id="comentarios" formControlName="comentarios" pInputTextarea class="p-element p-component w-full p-2 border rounded-lg"></textarea>
          </div>
        </div>
        </div>
      </div>

        <div class="grid grid-cols-3 gap-4 mb-6">

      </div>

      <div class="flex gap-2">
        <button pButton type="submit" class="p-2 btn btn-danger" [disabled]="!isEditing && !isCreating">Guardar</button>
        <button pButton (click)="cancelEdit()" class="p-2 p-button-primary btn btn-danger" [disabled]="!isEditing && !isCreating">Cancelar</button>
      </div>
  </form>
</p-card>

<p-card class="" [style]="{ 'padding-top': '0px', 'padding': '0px', 'margin': '16px' }">
  <!-- Tabla de historial de servicio -->
  <p-table [value]="historialServicios" styleClass="p-datatable-striped" [scrollable]="true">
    <ng-template pTemplate="caption">
      <div class="flex align-items-center justify-content-between">
        Lista de Historial de Servicio
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th>Dominio</th>
        <th>Fecha de Servicio</th>
        <th>ID Agencia</th>
        <th>Kilómetros de Ingreso</th>
        <th>Consultar</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-historialServicio>
      <tr>
        <td>{{ historialServicio.dominio }}</td>
        <td>{{ historialServicio.fechaServicio }}</td>
        <td>{{ historialServicio.idAgencia }}</td>
        <td>{{ historialServicio.kmIngreso }}</td>
        <td>
          <button pButton icon="pi pi-search" (click)="consultarHistorialServicio(historialServicio)" class="p-button-primary"></button>
        </td>
      </tr>
    </ng-template>
  </p-table>
</p-card>
