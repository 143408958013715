import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Proforma } from '../models/proforma.model'; // Ajusta la ruta según tu estructura de proyecto
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ProformaService {
    private baseUrl: string = environment.baseUrl + '/api/proformas';


  constructor(private http: HttpClient) {}

  /**
   * Obtener todas las proformas
   * @returns Observable con la lista de proformas
   */
  getAllProformas(): Observable<Proforma[]> {
    return this.http.get<Proforma[]>(this.baseUrl);
  }

  /**
   * Obtener una proforma por su ID
   * @param idSolicitud ID de la solicitud
   * @param idProforma ID de la proforma
   * @returns Observable con los datos de la proforma
   */
  getProformaBsyId(idSolicitud: number): Observable<Proforma[]> {
    return this.http.get<Proforma[]>(`${this.baseUrl}/solicitud/${idSolicitud}`);
  }

  /**
   * Crear una nueva proforma
   * @param proforma Datos de la nueva proforma
   * @returns Observable con la respuesta de la creación
   */
  createProforma(proforma: Proforma): Observable<Proforma> {
    return this.http.post<Proforma>(this.baseUrl, proforma);
  }

  /**
   * Actualizar una proforma existente
   * @param idSolicitud ID de la solicitud
   * @param idProforma ID de la proforma
   * @param proforma Datos actualizados de la proforma
   * @returns Observable con la respuesta de la actualización
   */
  updateProforma(
    idSolicitud: number,
    idProforma: string,
    proforma: Proforma
  ): Observable<Proforma> {
    return this.http.put<Proforma>(`${this.baseUrl}/${idSolicitud}/${idProforma}`, proforma);
  }

  /**
   * Eliminar una proforma
   * @param idSolicitud ID de la solicitud
   * @param idProforma ID de la proforma
   * @returns Observable con la respuesta de la eliminación
   */
  deleteProforma(idSolicitud: number, idProforma: string): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/${idSolicitud}/${idProforma}`);
  }
}
