import { environment } from './app/environments/environment';
import { ButtonModule } from 'primeng/button';
import { appConfig } from './app/app.config';
import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';  // Para PrimeNG
import { importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { routes } from './app/app.routes';
import { loadGapiInsideDOM } from 'gapi-script';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { enableProdMode } from '@angular/core';

loadGapiInsideDOM(); // Para cargar la biblioteca de Google

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

// Exponer la función getToken en el contexto global
(window as any).getToken = () => {
  const appComponent = platformBrowserDynamic().injector.get(AppComponent);
  return appComponent.getToken();
};

