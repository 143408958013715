import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Agencia } from '../../../models/agencia.model';

@Component({
  selector: 'app-agencias',
  templateUrl: './agencias.component.html',
  styleUrls: ['./agencias.component.scss'],
  providers: [ConfirmationService, MessageService]
})
export class AgenciasComponent implements OnInit {
  agencias: Agencia[] = [];
  selectedAgencia: Agencia | null = null;
  agenciaForm: FormGroup;
  isCreating: boolean = false;
  isEditing: boolean = false;
  deleteError: string | null = null;
  deleteSuccess: string | null = null;
  nombreSearch: string = '';

  constructor(
    private fb: FormBuilder,
    private confirmationService: ConfirmationService,
    private messageService: MessageService
  ) {
    this.agenciaForm = this.fb.group({
      idAgencia: [null],
      nombre: ['', [Validators.required]],
      cuitCuil: ['', [Validators.required]],
      movil: [''],
      correo: [''],
      calle: [''],
      numero: [''],
      provincia: [''],
      localidad: [''],
      codPostal: [''],
      ponderacion: [null],
      comentarios: ['']
    });
  }

  ngOnInit(): void {
    // Inicializar datos de agencias aquí
  }

  searchAgencia(): void {
    // Implementar lógica de búsqueda aquí
  }

  nuevoRegistro(): void {
    this.isCreating = true;
    this.agenciaForm.reset();
    this.selectedAgencia = null;
  }

  createAgencia(): void {
    if (this.agenciaForm.valid) {
      const newAgencia = this.agenciaForm.value as Agencia;
      this.agencias.push(newAgencia);
      this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Agencia creada exitosamente' });
      this.isCreating = false;
    }
  }

  editarAgencia(): void {
    if (this.selectedAgencia) {
      this.agenciaForm.patchValue(this.selectedAgencia);
      this.isCreating = false;
    }
  }

  updateAgencia(): void {
    if (this.agenciaForm.valid && this.selectedAgencia) {
      Object.assign(this.selectedAgencia, this.agenciaForm.value);
      this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Agencia actualizada exitosamente' });
      this.isCreating = false;
    }
  }

  deleteAgencia(): void {
    if (this.selectedAgencia) {
      this.confirmationService.confirm({
        message: '¿Está seguro de que desea eliminar esta agencia?',
        accept: () => {
          this.agencias = this.agencias.filter(a => a !== this.selectedAgencia);
          this.selectedAgencia = null;
          this.deleteSuccess = 'Agencia eliminada exitosamente';
          this.messageService.add({ severity: 'success', summary: 'Éxito', detail: this.deleteSuccess });
        },
        reject: () => {
          this.deleteError = 'Eliminación cancelada';
        }
      });
    }
  }

  cancelEdit(): void {
    this.isCreating = false;
    this.selectedAgencia = null;
    this.agenciaForm.reset();
  }

  consultarAgencia(agencia: Agencia): void {
    this.selectedAgencia = agencia;
    this.agenciaForm.patchValue(agencia);
  }
}
