i<div class="maindiv h-full flex items-center justify-center">
  <div class="bg-blue-900 absolute left-0 bg-gradient-to-b from-gray-900 via-gray-900 to-blue-800 bottom-0 leading-5 h-full w-full">
  </div>
  <div class="relative sm:flex sm:flex-row bg-transparent rounded-3xl mt-24">

    <div class="flex flex-col items-center justify-center lg:px-8 sm:max-w-4xl xl:w-1/3 z-10 hidden lg:flex text-gray-300" name="div-texto">
      <h1 class="my-3 font-semibold text-4xl">Welcome back</h1>
      <p class="pr-3 text-sm opacity-75">Kama - Gestión de unidades & Servicio Post-venta</p>
    </div>

    <div class="flex flex-col items-center justify-center lg:px-8 sm:max-w-4xl xl:w-1/3 mx-auto p-8" name="div-imagen">
      <img src="assets/LogoWebKama-01-1.png" alt="Logo" class="mx-auto logo-kama">
    </div>

    <div class="flex justify-center self-center z-10 w-full sm:w-2/3 md:w-1/2 lg:w-1/3" name="div-contenido">
      <div class="p-8 bg-white mx-auto rounded-3xl w-96 cuadrado-blanco" name="div-cuadrado-blanco">
        <div class="mb-7">
          <h3 class="font-semibold text-2xl text-gray-800">Sign In</h3>
          <p class="text-gray-400">Don't have an account? <a href="#" class="text-sm text-blue-700 hover:text-blue-700">Sign Up</a></p>
        </div>
        <div class="space-y-6">
          <div class="">
            <input [(ngModel)]="user" class="w-full text-sm px-4 py-3 bg-gray-200 focus:bg-gray-100 border border-gray-200 rounded-lg focus:outline-none focus:border-blue-400" type="" placeholder="Email">
          </div>

          <div class="relative" x-data="{ show: true }">
            <input [(ngModel)]="password" placeholder="Password" :type="password" style="color:black" class="text-sm text-gray-200 px-4 py-3 rounded-lg w-full bg-gray-200 focus:bg-gray-100 border border-gray-200 focus:outline-none focus:border-blue-400">
            <div class="flex items-center absolute inset-y-0 right-0 mr-3 text-sm leading-5">

            </div>
          </div>

          <div class="flex items-center justify-between">
            <div class="text-sm ml-auto">
              <a href="#" class="text-blue-700 hover:text-blue-600">
                Forgot your password?
              </a>
            </div>
          </div>
          <div>
            <button (click)="login()" type="submit" class="w-full flex justify-center bg-blue-800 hover:bg-blue-700 text-gray-100 p-3 rounded-lg tracking-wide font-semibold cursor-pointer transition ease-in duration-500">
              Sign in
            </button>
          </div>
          <div *ngIf="errorMessage" class="text-red-500 mb-4">
            {{ errorMessage }}
          </div>

          <div class="flex items-center justify-center space-x-2 my-5">
            <span class="h-px w-16 bg-gray-100"></span>
            <span class="text-gray-300 font-normal">or</span>
            <span class="h-px w-16 bg-gray-100"></span>
          </div>

          <div class="flex justify-center gap-5 w-full">
            <button (click)="loginGoogleLocal()" name="loginGoogle" type="submit" class="bg-blue-800 hover:bg-blue-700 w-full flex items-center justify-center mb-6 md:mb-0 border border-gray-300 hover:border-gray-900 hover:bg-gray-900 text-sm text-white-500 p-3 rounded-lg tracking-wide font-medium cursor-pointer transition ease-in duration-500">
              <svg class="w-4 mr-2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path fill="#EA4335" d="M5.266 9.765A7.077 7.077 0 0 1 12 4.909c1.69 0 3.218.6 4.418 1.582L19.91 3C17.782 1.145 15.055 0 12 0 7.27 0 3.198 2.698 1.24 6.65l4.026 3.115Z" />
                <path fill="#34A853" d="M16.04 18.013c-1.09.703-2.474 1.078-4.04 1.078a7.077 7.077 0 0 1-6.723-4.823l-4.04 3.067A11.965 11.965 0 0 0 12 24c2.933 0 5.735-1.043 7.834-3l-3.793-2.987Z" />
                <path fill="#4A90E2" d="M19.834 21c2.195-2.048 3.62-5.096 3.62-9 0-.71-.109-1.473-.272-2.182H12v4.637h6.436c-.317 1.559-1.17 2.766-2.395 3.558L19.834 21Z" />
                <path fill="#FBBC05" d="M5.277 14.268A7.12 7.12 0 0 1 4.909 12c0-.782.125-1.533.357-2.235L1.24 6.65A11.934 11.934 0 0 0 0 12c0 1.92.445 3.73 1.237 5.335l4.04-3.067Z" />
              </svg>
              <span>Google</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <svg class="absolute bottom-0 left-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
    <path fill="#fff" fill-opacity="1" d="M0,0L40,42.7C80,85,160,171,240,197.3C320,224,400,192,480,154.7C560,117,640,75,720,74.7C800,75,880,117,960,154.7C1040,192,1120,224,1200,213.3C1280,203,1360,149,1400,122.7L1440,96L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z">
    </path>
  </svg>
</div>
