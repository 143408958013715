import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UnidadVendida } from '../models/unidades-vendidas.model'; // Ajusta la ruta según tu estructura
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UnidadesVendidasService {
  private baseUrl = `${environment.baseUrl}/api/unidades-vendidas`;


  constructor(private http: HttpClient) {}

  /**
   * Obtener todas las unidades vendidas
   * @returns Observable con la lista de unidades vendidas
   */
  getAllUnidadesVendidas(): Observable<UnidadVendida[]> {
    return this.http.get<UnidadVendida[]>(this.baseUrl);
  }

  /**
   * Obtener una unidad vendida por dominio
   * @param dominio Dominio de la unidad vendida
   * @returns Observable con los datos de la unidad vendida
   */
  getUnidadVendidaByDominio(dominio: string): Observable<UnidadVendida> {
    return this.http.get<UnidadVendida>(`${this.baseUrl}/${dominio}`);
  }

  /**
   * Crear una nueva unidad vendida
   * @param unidadVendida Datos de la unidad vendida
   * @returns Observable con la unidad vendida creada
   */
  createUnidadVendida(unidadVendida: UnidadVendida): Observable<UnidadVendida> {

    console.log('createUnidadVendida() unidadVendida=' + JSON.stringify(unidadVendida));

    return this.http.post<UnidadVendida>(this.baseUrl, unidadVendida);
  }

  /**
   * Actualizar una unidad vendida existente
   * @param dominio Dominio de la unidad vendida
   * @param unidadVendida Datos actualizados de la unidad vendida
   * @returns Observable con la unidad vendida actualizada
   */
  updateUnidadVendida(
    dominio: string,
    unidadVendida: UnidadVendida
  ): Observable<UnidadVendida> {
    return this.http.put<UnidadVendida>(
      `${this.baseUrl}/${dominio}`,
      unidadVendida
    );
  }

  /**
   * Eliminar una unidad vendida
   * @param dominio Dominio de la unidad vendida
   * @returns Observable<void> indicando éxito
   */
  deleteUnidadVendida(dominio: string): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/${dominio}`);
  }

  /**
   * Buscar unidades vendidas por un término
   * @param termino Término de búsqueda
   * @returns Observable con las unidades vendidas que coincidan con el término
   */
    searchUnidadesVendidas(termino: string): Observable<UnidadVendida[]> {

      console.log('searchUnidadesVendidas() termino=' + termino);

      return this.http.get<UnidadVendida[]>(
        `${this.baseUrl}/buscar?termino=${encodeURIComponent(termino)}`
      );
    }
}
