<p-toast position="top-center"></p-toast>
<p-confirmDialog></p-confirmDialog>

<!-- Búsqueda de Tipos de Unidad -->
<p-card class="" [style]="{ 'margin-top': '0px', 'padding': '0px', 'margin-right': '16px', 'margin-left': '16px', 'margin-bottom': '8px', 'background-color': 'transparent !important' }">
  <div class="flex gap-4 items-center">
    <input pInputText [(ngModel)]="tipoUnidadSearch" class="p-inputtext p-component w-1/2 p-2 mb-4 border rounded-lg" placeholder="Buscar por Tipo de Unidad" />
    <button pButton icon="pi pi-search" label="Buscar" (click)="searchTipoUnidad()" class="p-button-primary mb-4"></button>
  </div>

  <!-- Fila de botones: Nuevo, Eliminar, Editar -->
  <div class="flex gap-2">
    <button pButton icon="pi pi-plus" label="Nuevo" (click)="nuevoRegistro()" class="p-button-success"></button>
    <button pButton icon="pi pi-trash" label="Eliminar" (click)="deleteTipoUnidad()" class="p-button-danger" [disabled]="!selectedTipoUnidad"></button>
    <button pButton icon="pi pi-pencil" label="Editar" (click)="editarTipoUnidad()" class="p-button-warning" [disabled]="!selectedTipoUnidad"></button>

    <!-- Mensaje de error para la eliminación de un Tipo de Unidad -->
    <div *ngIf="deleteError" class="text-red-600">
      <small>{{ deleteError }}</small>
    </div>

    <!-- Mensaje de éxito para la eliminación de un Tipo de Unidad -->
    <div *ngIf="deleteSuccess" class="text-green-600">
      <small>{{ deleteSuccess }}</small>
    </div>
  </div>
</p-card>

<!-- Formulario para crear o editar un Tipo de Unidad -->
<p-card class="" [style]="{ 'padding-top': '0px', 'padding': '0px', 'margin': '16px' }">
  <form [formGroup]="tipoUnidadForm" (ngSubmit)="isCreating ? createTipoUnidad() : updateTipoUnidad()">
    <div class="mx-auto rounded-3xl w-full">
      <h1 class="text-4xl font-semibold mb-4">Maestro de Tipos de Unidad</h1>
      <div class="rounded p-0 mt-0 mb-8">
        <h2 class="text-2xl font-semibold mb-2">Datos identificatorios</h2>
        <div class="grid grid-cols-4 gap-4 mb-6">
          <div class="p-field">
            <label for="tipoUnidad" class="block text-gray-700 font-semibold">Tipo de Unidad *</label>
            <input id="tipoUnidad" formControlName="tipoUnidad" pInputText class="p-inputtext w-full p-2 border rounded-lg" />
            <div *ngIf="tipoUnidadForm.get('tipoUnidad')?.invalid && tipoUnidadForm.get('tipoUnidad')?.touched">
              <small class="text-red-600" *ngIf="tipoUnidadForm.get('tipoUnidad')?.errors?.['required']">
                El Tipo de Unidad es requerido
              </small>
            </div>
          </div>
          <div class="p-field col-span-2">
            <label for="descripcion" class="block text-gray-700 font-semibold">Descripción *</label>
            <textarea id="descripcion" formControlName="descripcion" pInputTextarea class="w-full p-2 border rounded-lg"></textarea>
            <div *ngIf="tipoUnidadForm.get('descripcion')?.invalid && tipoUnidadForm.get('descripcion')?.touched">
              <small class="text-red-600" *ngIf="tipoUnidadForm.get('descripcion')?.errors?.['required']">
                La Descripción es requerida
              </small>
            </div>
          </div>
        </div>
      </div>

      <div class="flex gap-2">
        <button pButton type="submit" class="p-2 btn btn-danger" [disabled]="!isEditing && !isCreating">Guardar</button>
        <button pButton (click)="cancelEdit()" class="p-2 p-button-primary btn btn-danger" [disabled]="!isEditing && !isCreating">Cancelar</button>
      </div>
    </div>
  </form>
</p-card>

<p-card class="" [style]="{ 'padding-top': '0px', 'padding': '0px', 'margin': '16px' }">
  <!-- Tabla de tipos de unidad -->
  <p-table [value]="tiposUnidad" styleClass="p-datatable-striped" [scrollable]="true">
    <ng-template pTemplate="caption">
      <div class="flex align-items-center justify-content-between">
        Lista de Tipos de Unidad
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th>Tipo de Unidad</th>
        <th>Descripción</th>
        <th>Consultar</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-tipoUnidad>
      <tr>
        <td>{{ tipoUnidad.tipoUnidad }}</td>
        <td>{{ tipoUnidad.descripcion }}</td>
        <td>
          <button pButton icon="pi pi-search" (click)="consultarTipoUnidad(tipoUnidad)" class="p-button-primary"></button>
        </td>
      </tr>
    </ng-template>
  </p-table>
</p-card>
