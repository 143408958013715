<p-toast position="top-center"></p-toast>
<p-confirmDialog></p-confirmDialog>

<p-card class="" [styleClass]="customcard" [style]="{
    'margin-top': '0px',
    padding: '0px',
    'margin-right': '16px',
    'margin-left': '16px',
    'margin-bottom': '8px',
    'background-color': 'transparent !important'
  }">
  <div class="flex gap-4 items-center">
    <p-autoComplete [(ngModel)]="vinSearch" [suggestions]="vinsCargados" [showClear]="true" [minLength]="1"
      placeholder="Buscar por VIN" class="p-component w-1/2 mb-4 rounded-lg" (completeMethod)="searchVin($event)"
      (onSelect)="searchUnidadGeneral($event)" [dropdown]="true" [style]="{ width: '100%' }">
    </p-autoComplete>

    <button pButton icon="pi pi-search" label="Buscar" (click)="searchUnidadGeneralvin()"
      class="p-button-primary mb-4"></button>
  </div>

  <div class="flex gap-4 items-center">
    <button pButton icon="pi pi-plus" label="Nuevo" (click)="nuevoRegistro()" class="p-button-success"></button>
    <button pButton icon="pi pi-trash" label="Eliminar" (click)="deleteUnidad()" class="p-button-danger"
      [disabled]="!selectedUnidad"></button>
    <button pButton icon="pi pi-pencil" label="Editar" (click)="editarUnidad()" class="p-button-warning"
      [disabled]="!selectedUnidad"></button>
    <button pButton icon="pi pi-save" type="submit" class="p-2 btn btn-danger" [disabled]="!isEditing && !isCreating">
        Guardar
    </button>
    <button pButton icon="pi pi-times" (click)="cancelEdit()" class="p-2 p-button-primary btn btn-danger"
        [disabled]="!isEditing && !isCreating">
        Cancelar
    </button>
    <!-- añade un boton que al hacer click haga un this.router.navigate(['/maestro-unidades-vendidas', vin]) pasandole el vin como parametro;   -->
    <button pButton icon="pi pi-eye" label="Gestionar unidad vendida" (click)="callCreateUnidadVendida()" class="p-button-primary"
      [disabled]="!selectedUnidad"></button>

    <!-- Mensaje de error para la eliminación de una Unidad -->
    <div *ngIf="deleteError" class="text-red-600">
      <small>{{ deleteError }}</small>
    </div>

    <!-- Mensaje de éxito para la eliminación de una Unidad -->
    <div *ngIf="deleteSuccess" class="text-green-600">
      <small>{{ deleteSuccess }}</small>
    </div>

    <!-- Estado: {{ unidadForm.valid }} -->
  </div>
</p-card>

<p-card class="" [style]="{ 'padding-top': '0px', padding: '0px', margin: '16px' }">
  <form [formGroup]="unidadForm" (ngSubmit)="isCreating ? createUnidad() : updateUnidad()">
    <div class="mx-auto rounded-3xl w-full">
      <h1 class="text-4xl font-semibold mb-4">Maestro de Unidades</h1>
      <div class="rounded p-0' mt-0 mb-8">
        <h2 class="text-2xl font-semibold mb-2">Datos identificatorios</h2>
        <div class="grid grid-cols-4 gap-4 mb-6">
          <div class="p-field">
            <label for="vin" class="block text-white font-semibold">VIN *</label>
            <input id="vin" formControlName="vin" pInputText class="p-inputtext w-full p-2 border rounded-lg" />
            <div *ngIf="
                unidadForm.get('vin')?.invalid && unidadForm.get('vin')?.touched
              ">
              <small class="text-red-600" *ngIf="unidadForm.get('vin')?.errors?.['maxlength']">
                El VIN no puede exceder los 24 caracteres.
              </small>

              <small class="text-red-600" *ngIf="unidadForm.get('vin')?.errors?.['required']">
                El VIN es requerido
              </small>
            </div>
          </div>
          <div class="p-field">
            <label for="nroMotor" class="block text-white font-semibold">Número de Motor *
            </label>
            <input id="nroMotor" formControlName="nroMotor" pInputText class="w-full p-2 border rounded-lg" />
            <div *ngIf="
                unidadForm.get('nroMotor')?.invalid &&
                unidadForm.get('nroMotor')?.touched
              ">
              <small class="text-red-600" *ngIf="unidadForm.get('nroMotor')?.errors?.['maxlength']">
                El MOTOR no puede exceder los 24 caracteres.
              </small>

              <small class="text-red-600" *ngIf="unidadForm.get('nroMotor')?.errors?.['required']">
                El MOTOR es requerido
              </small>
            </div>
          </div>
          <div class="p-field">
            <label for="tipoUnidad" class="block text-white font-semibold">Tipo de Unidad *</label>

            <p-dropdown id="tipoUnidad" formControlName="tipoUnidad" [options]="tipoUnidadOptions"
              class="w-full p-dropdown-sm p-2" [showClear]="true" [filter]="true"
              (onChange)="handleTipoUnidadChange()"
              filterPlaceholder="Buscar Tipo de unidad" [ngClass]="{
                'p-invalid':
                  unidadForm.get('tipoUnidad')?.invalid &&
                  (unidadForm.get('tipoUnidad')?.dirty ||
                    unidadForm.get('tipoUnidad')?.touched)
              }" [style]="{ height: '2.6rem', width: '100%' }"></p-dropdown>
            <div *ngIf="
                unidadForm.get('tipoUnidad')?.invalid &&
                unidadForm.get('tipoUnidad')?.touched
              ">
              <small class="text-red-600" *ngIf="unidadForm.get('tipoUnidad')?.errors?.['maxlength']">
                El Tipo de Unidad no puede exceder los 3 caracteres.
              </small>

              <small class="text-red-600" *ngIf="unidadForm.get('tipoUnidad')?.errors?.['required']">
                El Tipo de Unidad es requerido
              </small>
            </div>
          </div>
          <div class="p-field">
            <label for="idUnidad" class="block text-white font-semibold">ID Unidad *</label>
            <p-dropdown id="idUnidad" formControlName="idUnidad" [options]="catalogo" [showClear]="true" [filter]="true"
              filterPlaceholder="Buscar ID Unidad" class="w-full p-dropdown-sm p-2"
              [style]="{ height: '2.6rem', width: '100%' }" [ngClass]="{
                'p-invalid':
                  unidadForm.get('idUnidad')?.invalid &&
                  (unidadForm.get('idUnidad')?.dirty ||
                    unidadForm.get('idUnidad')?.touched)
              }">
            </p-dropdown>

            <!-- Mensaje de error para ID Unidad -->
            <div *ngIf="
                unidadForm.get('idUnidad')?.invalid &&
                unidadForm.get('idUnidad')?.touched
              ">
              <small class="text-red-600" *ngIf="unidadForm.get('idUnidad')?.errors?.['maxlength']">
                El ID Unidad no puede exceder los 20 caracteres.
              </small>

              <small class="text-red-600" *ngIf="unidadForm.get('idUnidad')?.errors?.['required']">
                El ID Unidad es requerido
              </small>
            </div>
          </div>
        </div>
      </div>

      <div class="border border-gray-300 rounded p-2 mb-8">
        <h2 class="text-2xl font-semibold mb-4">Importación</h2>
        <div class="grid grid-cols-3 gap-4 mb-6">
          <!-- Renglon 1 -->
          <div class="p-field">
            <label for="idSolicitud" class="block text-white font-semibold">ID Solicitud</label>
            <p-dropdown id="idSolicitud" formControlName="idSolicitud" [showClear]="true" [filter]="true"
              filterPlaceholder="Buscar ID Solicitud" [options]="solicitudesOptions" class="w-full p-dropdown-sm p-2"
              (onChange)="handleSolicitudChange()"
              [ngClass]="{
              'p-invalid':
                unidadForm.get('idSolicitud')?.invalid &&
                (unidadForm.get('idSolicitud')?.dirty ||
                unidadForm.get('idSolicitud')?.touched)
              }" [style]="{ height: '2.6rem', width: '100%' }">
          </p-dropdown>
            <div *ngIf="
              unidadForm.get('idSolicitud')?.invalid &&
              unidadForm.get('idSolicitud')?.touched
              ">
              <small class="text-red-600" *ngIf="unidadForm.get('idSolicitud')?.errors?.['required']">
              El ID Solicitud es requerido
              </small>
            </div>
          </div>

          <div class="p-field col-span-2">
            <label for="idProforma" class="block text-white font-semibold">ID Proforma *</label>
            <p-dropdown id="idProforma" formControlName="idProforma" [showClear]="true" [filter]="true"
              filterPlaceholder="Buscar ID Proforma" [options]="proformaOptions" class="w-full p-dropdown-sm p-2"
              [ngClass]="{
                'p-invalid':
                  unidadForm.get('idProforma')?.invalid &&
                  (unidadForm.get('idProforma')?.dirty ||
                    unidadForm.get('idProforma')?.touched)
              }" [style]="{ height: '2.6rem', width: '40%' }">
            </p-dropdown>
            <div *ngIf="
                unidadForm.get('idProforma')?.invalid &&
                unidadForm.get('idProforma')?.touched
              ">
              <small class="text-red-600" *ngIf="unidadForm.get('idProforma')?.errors?.['required']">
                El ID Proforma es requerido
              </small>
            </div>
          </div>

          <!-- Renglon 2. -->
          <div class="p-field">
            <label for="idEmpaque" class="block text-white font-semibold">ID Empaque *</label>
            <p-dropdown id="idEmpaque" formControlName="idEmpaque" [showClear]="true" [filter]="true"
              filterPlaceholder="Buscar ID Empaque" [options]="empaqueOptions" class="w-full p-dropdown-sm p-2"
              (onChange)="handleEmpaqueChange()"
              [ngClass]="{
                'p-invalid':
                  unidadForm.get('idEmpaque')?.invalid &&
                  (unidadForm.get('idEmpaque')?.dirty ||
                    unidadForm.get('idEmpaque')?.touched)
              }" [style]="{ height: '2.6rem', width: '100%' }">
            </p-dropdown>
            <div *ngIf="
                unidadForm.get('idEmpaque')?.invalid &&
                unidadForm.get('idEmpaque')?.touched
              ">
              <small class="text-red-600" *ngIf="unidadForm.get('idEmpaque')?.errors?.['required']">
                El ID Empaque es requerido
              </small>
            </div>
          </div>

          <div class="p-field">
            <label for="idBl" class="block text-white font-semibold">Bill of Landing *</label>
            <p-dropdown id="idBl" formControlName="idBl" [showClear]="true" [filter]="true"
              filterPlaceholder="Buscar ID Bill of Landing" [options]="billOfLandingOptions"
              (onChange)="handleBillOfLandingChange()"
              class="w-full p-dropdown-sm p-2" [ngClass]="{
                'p-invalid':
                  unidadForm.get('idBl')?.invalid &&
                  (unidadForm.get('idBl')?.dirty ||
                    unidadForm.get('idBl')?.touched)
              }" [style]="{ height: '2.6rem', width: '100%' }">
            </p-dropdown>
            <div *ngIf="
                unidadForm.get('idBl')?.invalid &&
                unidadForm.get('idBl')?.touched
              ">
              <small class="text-red-600" *ngIf="unidadForm.get('idBl')?.errors?.['required']">
                El Bill of Landing es requerido
              </small>
            </div>
          </div>

          <div class="p-field">
            <label for="idFactura" class="block text-white font-semibold">ID Factura *</label>
            <p-dropdown id="idFactura" formControlName="idFactura" [showClear]="true" [filter]="true"
              filterPlaceholder="Buscar ID Factura" [options]="facturaOptions" class="w-full p-dropdown-sm p-2"
              [ngClass]="{
                'p-invalid':
                  unidadForm.get('idFactura')?.invalid &&
                  (unidadForm.get('idFactura')?.dirty ||
                    unidadForm.get('idFactura')?.touched)
              }" [style]="{ height: '2.6rem', width: '100%' }">
            </p-dropdown>
            <div *ngIf="
                unidadForm.get('idFactura')?.invalid &&
                unidadForm.get('idFactura')?.touched
              ">
              <small class="text-red-600" *ngIf="unidadForm.get('idFactura')?.errors?.['required']">
                El ID Factura es requerido
              </small>
            </div>
          </div>
        </div>
      </div>

      <div class="border border-gray-300 rounded p-2 mb-8">
        <h2 class="text-2xl font-semibold mb-4">Pre-Entrega</h2>

        <!-- Tabla de incidencias usando p-table -->
        <p-table [value]="incidencias.controls" class="mb-6 overflow-visible">
          <ng-template pTemplate="header">
            <tr>
              <th>Tipo de Incidencia</th>
              <th>Descripción</th>
              <th>Acción</th>
              <th>Valor</th>
              <th>Moneda</th>
              <th>Acciones</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-incidencia let-i="rowIndex">
            <tr [formGroup]="incidencia">
              <td>
                <p-dropdown [options]="incidenciaOptions" formControlName="tipo" class="w-full"
                  appendTo="body"></p-dropdown>
              </td>
              <td>
                <input type="text" pInputText formControlName="descripcion" class="w-full" />
              </td>
              <td>
                <input type="text" pInputText formControlName="accion" class="w-full" />
              </td>
              <td>
                <input type="number" pInputText formControlName="valor" class="w-full" />
              </td>
              <td>
                <p-dropdown id="moneda" formControlName="moneda" class="w-full p-dropdown-sm p-2" [showClear]="false" [filter]="false"
                appendTo="body"
                  filterPlaceholder="Buscar Moneda" [options]="monedasOptions" [ngClass]="{
                  'p-invalid':
                    incidencia.get('moneda')?.invalid &&
                    (incidencia.get('moneda')?.dirty ||
                    incidencia.get('moneda')?.touched)
                  }" [style]="{ height: '2.6rem', width: '100%' }">
                </p-dropdown>
              </td>
              <td>
                <button pButton icon="pi pi-trash" type="button" (click)="removeIncidencia(i)" class="p-button-danger"
                  [disabled]="!isEditing && !isCreating"></button>
              </td>
            </tr>
          </ng-template>
        </p-table>

        <div class="mt-6">
          <button pButton label="Añadir Incidencia" type="button" [disabled]="!isEditing && !isCreating"
            (click)="addIncidencia()" class="p-button-primary"></button>
        </div>
      </div>

      <div class="border border-gray-300 rounded p-2 mb-8">
        <h2 class="text-2xl font-semibold mb-4">Entrega</h2>
        <div class="grid grid-cols-2 gap-4 mb-6">
          <div class="p-field">
            <label for="tipoAcuerdo" class="block text-white">Acuerdo de Entrega</label>
            <p-dropdown id="tipoAcuerdo" [showClear]="true" [filter]="true" filterPlaceholder="Buscar Tipo de Acuerdo"
              formControlName="tipoAcuerdo" [options]="acuerdos" class="" autoWidth="false"
              [style]="{ minWidth: '50px', width: '70%', height: '2.6rem' }"></p-dropdown>
          </div>
          <div class="p-field">
            <label for="idAgencia" class="block text-white">ID Agencia *</label>
            <p-dropdown id="idAgencia" formControlName="idAgencia" class="w-full p-dropdown-sm p-2" [ngClass]="{
                'p-invalid':
                  unidadForm.get('idAgencia')?.invalid &&
                  (unidadForm.get('idAgencia')?.dirty ||
                    unidadForm.get('idAgencia')?.touched)
              }" [showClear]="true" [filter]="true" filterPlaceholder="Buscar Agencia" [options]="agencias"
              autoWidth="false" [style]="{ width: '15rem', height: '2.6rem' }"></p-dropdown>
            <div *ngIf="
                unidadForm.get('idAgencia')?.invalid &&
                unidadForm.get('idAgencia')?.touched
              ">
              <small class="text-red-600"> El ID Agencia es requerido </small>
            </div>
          </div>
          <div class="p-field">
            <label for="fechaEntrega" class="block text-white">Fecha de Entrega</label>
            <p-calendar id="fechaEntrega" formControlName="fechaEntrega" inputStyleClass="w-full p-2 border rounded-lg"
              [style]="{ minWidth: '50px', width: '50%' }" [readonlyInput]="!isEditing && !isCreating"></p-calendar>
          </div>
          <div class="p-field">
            <label for="fechaAcuerdo" class="block text-white">Fecha de Acuerdo</label>
            <p-calendar id="fechaAcuerdo" class="p-input-element" formControlName="fechaAcuerdo"
              inputStyleClass="w-full p-2 border rounded-lg p-input-element"
              [style]="{ minWidth: '50px', width: '50%' }" [readonlyInput]="!isEditing && !isCreating"></p-calendar>
          </div>
        </div>
      </div>

      <div class="flex gap-2">
        <button pButton type="submit" class="p-2 btn btn-danger" [disabled]="!isEditing && !isCreating">
          Guardar
        </button>
        <button pButton (click)="cancelEdit()" class="p-2 p-button-primary btn btn-danger"
          [disabled]="!isEditing && !isCreating">
          Cancelar
        </button>
        <!-- Añadir boton para descargar el QR -->
        <button pButton label="Descargar QR" (click)="downloadQR()" class="p-button-primary"
          [disabled]="!selectedUnidad"></button>
      </div>
    </div>
  </form>
</p-card>

<!-- <p-card class="" [styleClass]="customcard" [style]="{ 'margin-top': '0px', 'padding': '0px', 'margin-right': '16px', 'margin-left': '16px', 'margin-bottom': '8px', 'background-color': 'transparent !important' }"></p-card> -->
<p-card class="" [style]="{ 'padding-top': '0px', padding: '0px', margin: '16px' }">
  <!-- Tabla de unidades -->
  <p-table [value]="unidades" styleClass="p-datatable-striped" [scrollable]="true">
    <ng-template pTemplate="caption">
      <div class="flex align-items-center justify-content-between">
        Lista de Unidades
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th>VIN</th>
        <th>Tipo de Unidad</th>
        <th>ID Unidad</th>
        <th>Número de Motor</th>
        <th>Consultar</th>
        <!-- <th>ID BL</th> -->
        <!-- <th>ID Factura</th> -->
        <!-- <th>Dominio</th> -->
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-unidad>
      <!-- <tr (click)="searchUnidadGeneral(unidad.vin)"> -->
      <tr>
        <td>{{ unidad.vin }}</td>
        <td>{{ unidad.tipoUnidad }}</td>
        <td>{{ unidad.idUnidad }}</td>
        <td>{{ unidad.nroMotor }}</td>
        <!-- inserta un boton de consulta con lupa x-->
        <td>
          <button pButton icon="pi pi-search" (click)="searchUnidadGeneralvin(unidad.vin)"
            class="p-button-primary"></button>
        </td>

        <!-- <td>{{ unidad.idBl }}</td> -->
        <!-- <td>{{ unidad.idFactura }}</td> -->
        <!-- <td>{{ unidad.dominio }}</td> -->
      </tr>
    </ng-template>
  </p-table>
</p-card>
