import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Unidad } from '../models/unidad-maestro.model';
import { environment } from '../environments/environment';
import { UnidadDTO } from '../models/unidaddto.model';

@Injectable({
  providedIn: 'root'
})
export class UnidadService {
  private apiUrl = `${environment.baseUrl}/api/unidades`;
  private qrApiUrl = `${environment.baseUrl}/api/unidades/qr`;


  constructor(private http: HttpClient) {}

  getUnidades(): Observable<Unidad[]> {
    return this.http.get<Unidad[]>(`${this.apiUrl}`);
  }

  getUnidadesByVin(vin: string): Observable<Unidad[]> {
    return this.http.get<Unidad[]>(`${this.apiUrl}/buscar?termino=${vin}`);
  }

  getUnidadById(vin: string, tipoUnidad: string, idUnidad: number): Observable<Unidad> {
    return this.http.get<Unidad>(`${this.apiUrl}/vin/${vin}/tipoUnidad/${tipoUnidad}/idUnidad/${idUnidad}`);
  }

  createUnidad(unidad: Unidad): Observable<void> {

    console.log(unidad);
    return this.http.post<void>(`${this.apiUrl}`, unidad);
  }

  updateUnidad(id: { vin: string, tipoUnidad: string, idUnidad: number, nroMotor: string}, unidad: Unidad): Observable<void> {
    return this.http.put<void>(`${this.apiUrl}/${id.vin}/${id.tipoUnidad}/${id.idUnidad}/${id.nroMotor}`, unidad);
  }

  deleteUnidad(id: { vin: string, tipoUnidad: string, idUnidad: number, nroMotor: string }): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/${id.vin}/${id.tipoUnidad}/${id.idUnidad}/${id.nroMotor}`);
  }

  // getUnidadesByTipoUnidad(tipoUnidad: string): Observable<Catalogo[]> {
  //   return this.http.get<Unidad[]>(`${this.apiUrl}/tipo/${tipoUnidad}`);
  // }

  // Manejo de errores HTTP
  private handleError(error: HttpErrorResponse) {
    // Aquí puedes agregar lógica adicional de manejo de errores
    return throwError(() => new Error('Error en la comunicación con el servidor.'));
  }

  /**
     * Obtener una unidad por el código QR.
     * @param vin VIN de la unidad.
     * @param tipoUnidad Tipo de unidad.
     * @param idUnidad ID de la unidad.
     * @param nroMotor Número de motor.
     * @returns Observable con la información de la unidad.
     */
  obtenerUnidadPorQr(vin: string, tipoUnidad: string, idUnidad: number, nroMotor: string): Observable<UnidadDTO> {
    const url = `${this.qrApiUrl}/${vin}/${tipoUnidad}/${idUnidad}/${nroMotor}`;
    return this.http.get<UnidadDTO>(url);
  }

  /**
   * Obtener la imagen del código QR de una unidad.
   * @param vin VIN de la unidad.
   * @param tipoUnidad Tipo de unidad.
   * @param idUnidad ID de la unidad.
   * @param nroMotor Número de motor.
   * @returns Observable con la imagen del QR en formato Blob.
   */
  obtenerImagenQr(vin: string, tipoUnidad: string, idUnidad: number, nroMotor: string): Observable<Blob> {
    const url = `${this.qrApiUrl}/${vin}/${tipoUnidad}/${idUnidad}/${nroMotor}/image`;
    return this.http.get(url, { responseType: 'blob' });
  }

  /**
   * Regenerar el código QR de una unidad.
   * @param vin VIN de la unidad.
   * @param tipoUnidad Tipo de unidad.
   * @param idUnidad ID de la unidad.
   * @param nroMotor Número de motor.
   * @returns Observable indicando el resultado de la operación.
   */
  regenerarQr(vin: string, tipoUnidad: string, idUnidad: number, nroMotor: string): Observable<void> {
    const url = `${this.qrApiUrl}/${vin}/${tipoUnidad}/${idUnidad}/${nroMotor}/regenerate`;
    return this.http.post<void>(url, {});
  }

}
