import { CatalogoService } from './../../../services/catalogo.service';
import { TiposUnidadService } from '../../../services/tipos-unidad.service';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UnidadService } from '../../../services/unidad-maestro.service';
import { AgenciaService } from '../../../services/agencia.service';
import { AcuerdoService } from '../../../services/acuerdo.service'; // Importar AcuerdoService
import { MessageService } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';
import { Unidad } from '../../../models/unidad-maestro.model';
import { Agencia } from '../../../models/agencia.model';
import { Acuerdo } from '../../../models/acuerdo.model'; // Importar Acuerdo
import { InspeccionPreentrega } from '../../../models/inspeccion-preentrega.model';
import { InspeccionDetalle } from '../../../models/inspeccion-detalle.model';
import { InspeccionDetalleService } from '../../../services/inspeccion-detalle.service';
import { InspeccionPreentregaService } from '../../../services/inspecciones-preentrega.service';
import { AutoCompleteCompleteEvent } from 'primeng/autocomplete';
import { Catalogo } from '../../../models/catalogo.model';
import { ProformaService } from '../../../services/proformas.service';
import { BillOfLandingService } from '../../../services/bill-of-landing.service'; // Importar el servicio de Bill of Landing
import { EmpaqueService } from '../../../services/empaques.service';
import { FacturaService } from '../../../services/factura.service'; // Importar el servicio de Factura
import { SolicitudesService } from '../../../services/solicitud.service';
import { Solicitud } from '../../../models/solicitud.model';
import { MonedasService } from '../../../services/moneda.service';
import { Moneda } from '../../../models/moneda.model';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-unidad-crud',
  templateUrl: './unidad-crud.component.html',
  styleUrls: ['./unidad-crud.component.scss'],
  providers: [MessageService, ConfirmationService]
})
export class UnidadCrudComponent implements OnInit {
  unidadForm: FormGroup;
  unidades: Unidad[] = [];
  vinsCargados: string[] = [];
  isCreateMode: boolean = false;

  monedasOptions: { label: string, value: any }[] = []; // Opciones para el dropdown de Monedas
  proformaOptions: { label: string, value: any }[] = []; // Opciones para el dropdown de Proforma
  catalogo: { label: string, value: any }[] = [];
  agencias: { label: string, value: any }[] = [];
  acuerdos: { label: string, value: any }[] = [];
  tipoUnidadOptions: { label: string, value: any }[] = [];
  billOfLandingOptions: { label: string, value: any }[] = []; // Opciones para el dropdown de Bill of Landing
  empaqueOptions: { label: string, value: any }[] = []; // Opciones para el dropdown de Empaque
  facturaOptions: { label: string, value: any }[] = []; // Opciones para el dropdown de Factura
  solicitudesOptions: { label: string, value: any }[] = []; // Opciones para el dropdown de Solicitud

  incidenciaOptions: String[] = ['Daño', 'Faltante', 'Otro']; // Opciones para el dropdown de incidencias
  inspeccion: InspeccionPreentrega | null = null;
  inspeccionDetalle: InspeccionDetalle[] = []; // Array para almacenar los detalles de inspección
  inspeccionDetalleOri: InspeccionDetalle[] = []; // Array para almacenar los detalles de inspección originales


  customcard: string = 'customcard'; // Define la propiedad customcard

  deleteSuccess: string = '';
  deleteError: string = '';
  vinSearch: string = '';

  selectedUnidad: Unidad | null = null;
  isEditing = false;
  isCreating = false;
  // window: any;

  constructor(
    private fb: FormBuilder,
    private unidadService: UnidadService,
    private agenciaService: AgenciaService,
    private proformaService: ProformaService, // Inyectar el servicio de Proforma
    private facturaService: FacturaService, // Inyectar el servicio de Factura
    private solicitudService: SolicitudesService,
    private router: Router,
    private route: ActivatedRoute,
    private catalogoService: CatalogoService,
    private acuerdoService: AcuerdoService, // Inyectar AcuerdoService
    private billOfLandingService: BillOfLandingService, // Inyectar el servicio de Bill of Landing
    private monedasService: MonedasService,
    private tiposUnidadService: TiposUnidadService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private empaqueService: EmpaqueService,
    private inspeccionPreentregaService: InspeccionPreentregaService, // Inyectar InspeccionPreentregaService
    private inspeccionDetalleService: InspeccionDetalleService // Inyectar Inspeccion
  ) {
    this.unidadForm = this.fb.group({
      vin: new FormControl({ value: '', disabled: true }, [Validators.required, Validators.maxLength(24)]),
      tipoUnidad: new FormControl({ value: '', disabled: true }, [Validators.required, Validators.maxLength(3)]),
      idUnidad: new FormControl({ value: '', disabled: true }, [Validators.required, Validators.maxLength(20)]),
      nroMotor: new FormControl({ value: '', disabled: true }, [Validators.required, Validators.maxLength(24)]),
      idEmpaque: new FormControl({ value: null, disabled: true }),
      idBl: new FormControl({ value: '', disabled: true }, Validators.required),
      idFactura: new FormControl({ value: '', disabled: true }, Validators.required),
      idSolicitud: new FormControl({ value: null, disabled: true }, Validators.required),
      idProforma: new FormControl({ value: '', disabled: true }, Validators.required),
      idAgencia: new FormControl({ value: null, disabled: true }, Validators.required),
      fechaEntrega: new FormControl({ value: null, disabled: true }),
      tipoAcuerdo: new FormControl({ value: '', disabled: true }),
      fechaAcuerdo: new FormControl({ value: null, disabled: true }),
      certDnrpa: new FormControl({ value: '', disabled: true }),
      cert01: new FormControl({ value: null, disabled: true }),
      dominio: new FormControl({ value: '', disabled: true }),
      qrIdentifier: new FormControl({ value: '', disabled: true }),
      incidencias: this.fb.array([]) // FormArray para incidencias
    });
  }

  ngOnInit(): void {

    this.route.params.subscribe(params => {

      // console.log(this.unidadForm); // Verifica que el formGroup esté inicializado

      this.loadSolicitudes();
      this.loadMaestroUnidades();
      this.loadAgencias(); // Cargar agencias al inicializar el componente
      this.loadAcuerdos(); // Cargar acuerdos al inicializar el componente
      this.loadTiposUnidad();
      this.loadCatalogo();
      this.loadProformas();
      this.loadBillOfLandings();
      this.loadEmpaques();
      this.loadFacturas();
      this.loadMonedas();

      this.isCreateMode = this.route.snapshot.url.some(segment => segment.path === 'create');

      if (this.isCreateMode) {

        console.log('isCreateMode:', this.isCreateMode);

        if (!params['vin']) {
          //error
          console.error('error no hay vin en la ruta');
          return;
        }

        this.vinSearch = params['vin'] || '';
        this.nuevoRegistro();

        console.log(this.vinSearch);
      }
      else {

        console.log('should not be in CreateMode:', this.isCreateMode);

        if (params['vin']) {
          this.vinSearch = params['vin'] || '';
          console.log(this.vinSearch);

          if (this.vinSearch) {
            this.searchUnidadGeneralvin(this.vinSearch);
          }
        }
      }
    });
  }

  // Método en un componente para cargar opciones de monedas
  loadMonedas(): void {
    this.monedasService.getAllMonedas().subscribe(
      (data: Moneda[]) => {
        console.log(data);

        this.monedasOptions = data.map(moneda => ({
          label: `${moneda.nombre} (${moneda.simbolo})`,
          value: moneda.idMoneda
        }));
      },
      (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al cargar monedas' });
      }
    );
  }

  searchVin(event: AutoCompleteCompleteEvent) {
    let filtered: string[] = [];
    let query = event.query;

    for (let unidad of this.unidades) {
        if (unidad.vin.toLowerCase().indexOf(query.toLowerCase()) == 0) {
            filtered.push(unidad.vin);
        }
    }

    this.vinsCargados = filtered;
  }

  searchUnidadGeneral(event: any): void {
    const vin = event.query;
    this.searchUnidadGeneralvin(vin);
  }

  searchUnidadGeneralvin(pvin?: string): void {
    const vin = pvin ? pvin : this.vinSearch;

    console.log('Buscando unidad con VIN:', vin, 'pvin: ', pvin);
    this.unidadService.getUnidadesByVin(vin).subscribe(
      (unidadArray: Unidad[]) => {
        console.log('Unidad encontrada:', unidadArray);

        if (unidadArray && unidadArray.length > 0) {
          this.selectedUnidad = unidadArray[0]; // Acceder al primer elemento del array
          // console.log('selectedUnidad:', this.selectedUnidad);

          //Transforma el array en que se encuentra la fecha de entrega, y la fecha del acuerdo a un formato de fecha
          //Las fechas vienen en este formato: fechaEntrega: [2021, 6, 30], fechaAcuerdo: [2021, 6, 30]
          if (this.selectedUnidad.fechaEntrega) {
            const [year, month, day] = this.selectedUnidad.fechaEntrega.map(Number);
            this.selectedUnidad.fechaEntregaDate = new Date(year, month - 1, day);
            this.selectedUnidad.fechaEntrega =this.selectedUnidad.fechaEntregaDate;
          }

          console.log('this: ', this.selectedUnidad.fechaEntrega);

          if (this.selectedUnidad.fechaAcuerdo) {
            const [year, month, day] = this.selectedUnidad.fechaAcuerdo.map(Number);
            this.selectedUnidad.fechaAcuerdoDate = new Date(year, month - 1, day);
            this.selectedUnidad.fechaAcuerdo = this.selectedUnidad.fechaAcuerdoDate;
          }

          this.incidencias.clear(); // Limpia el FormArray de incidencias

          this.inspeccionPreentregaService.getInspeccionByVin(this.selectedUnidad.vin).subscribe(
            (inspeccion: InspeccionPreentrega) => {
              // console.log('Inspecciones encontradas:', inspeccion);
              this.inspeccion = inspeccion;

                this.inspeccionDetalleService.getDetalleByVin(this.selectedUnidad!.vin).subscribe(
                  (inspeccionDetalle: InspeccionDetalle[]) => {
                    // console.log('Detalles de inspección encontrados:', inspeccionDetalle);
                    this.inspeccionDetalle = inspeccionDetalle;
                    this.inspeccionDetalleOri = inspeccionDetalle;

                    // Itera sobre los detalles de inspección para agregarlos al FormArray
                    inspeccionDetalle.forEach((detalle) => {
                      const incidenciaGroup = this.fb.group({
                        tipo: [{ value: detalle.tipoPunto, disabled: true }],
                        descripcion: [{ value: detalle.descripcion, disabled: true }],
                        accion: [{ value: detalle.accion, disabled: true }],
                        valor: [{ value: detalle.valor, disabled: true }],
                        moneda: [{ value: detalle.idMoneda, disabled: true }]
                      });
                      this.incidencias.push(incidenciaGroup);
                    });
                  },
                  (error) => {
                    console.log('Error al buscar detalles de inspección:', error);
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Detalles de inspección no encontrados' });
                  }
                );
            },
            (error) => {
              console.log('Error al buscar inspecciones:', error);
              this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Inspecciones no encontradas' });
            }
          );

          // Mapea los valores de selectedUnidad al formulario usando patchValue
          this.unidadForm.patchValue(this.selectedUnidad);
          console.log('Formulario después de patchValue:', this.unidadForm.value);

          this.isEditing = false;
          this.isCreating = false;
          this.disableForm();

          // Lleva al usuario al inicio de la pagina
          window.scrollTo(0, 0);

        } else {
          console.warn('No se encontró ninguna unidad.');
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Unidad no encontrada' });
        }
      },
      (error) => {
        console.log('Error al buscar unidad:', error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Unidad no encontrada' });
      }
    );
  }


  get incidencias(): FormArray {
    return this.unidadForm.get('incidencias') as FormArray;
  }

  loadSolicitudes(): void {
    this.solicitudService.getAllSolicitudes().subscribe(
      (data: Solicitud[]) => {
        console.log(data);

        this.solicitudesOptions = data.map(solicitud => ({
          label: solicitud.idSolicitud + '', // Ajusta según la propiedad que quieras mostrar
          value: solicitud.idSolicitud
        }));
      },
      (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al cargar solicitudes' });
      }
    );
  }

  loadFacturas(): void {
    this.facturaService.getAll().subscribe(
      (data) => {
        this.facturaOptions = data.map(factura => ({
          label: factura.idFactura, // Ajusta según la propiedad que quieras mostrar
          value: factura.idFactura
        }));
      },
      (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al cargar facturas' });
      }
    );
  }

  handleTipoUnidadChange(): void {

    if (!this.unidadForm.get('tipoUnidad')?.value) {
      this.catalogo = [];
      this.proformaOptions = [];
      this.billOfLandingOptions = [];
      this.facturaOptions = [];
      return;
    }


    this.loadUnidadesByTipoUnidad();

  }

  loadUnidadesByTipoUnidad(): void {
    const tipoUnidad = this.unidadForm.get('tipoUnidad')?.value;

    if (!tipoUnidad) {
      console.warn('Tipo de unidad no proporcionado.');
      this.messageService.add({ severity: 'warn', summary: 'Advertencia', detail: 'Por favor, seleccione un tipo de unidad.' });
      return;
    }

    console.log('Buscando unidades con tipo de unidad:', tipoUnidad);
    this.catalogoService.getItemByTipoUnidad(tipoUnidad).subscribe(
      (catalogo: Catalogo[] | null) => {
        if (!catalogo || catalogo.length === 0) {
          console.warn('No se encontraron unidades para el tipo de unidad:', tipoUnidad);
          this.messageService.add({ severity: 'info', summary: 'Información', detail: 'No se encontraron unidades.' });
          this.catalogo = [];
          return;
        }

        console.log('Unidades encontradas:', catalogo);

        this.catalogo = catalogo.map(unidad => ({
          label: unidad.nombre, // Ajusta según la propiedad que quieras mostrar
          value: unidad.idUnidad
        }));

      },
      (error) => {
        console.error('Error al buscar unidades:', error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'No se pudieron cargar las unidades.' });
      }
    );
  }


  nuevoRegistro(): void {
    this.unidadForm.reset();
    this.isEditing = true;
    this.isCreating = true;
    this.selectedUnidad = null;
    this.enableForm();

    this.unidadForm.get('vin')?.setValue(this.vinSearch);

    // Agrega una nueva incidencia al FormArray
    // this.addIncidencia();

    // Añade un valor por defecto a los campos obligatorios
    // this.unidadForm.get('vin')?.setValue('vin1');
    // this.unidadForm.get
    // this.unidadForm.get('idUnidad')?.setValue(1);
    // this.unidadForm.get('nroMotor')?.setValue(2);
    // this.unidadForm.get('idBl')?.setValue(1);
    // this.unidadForm.get('idFactura')?.setValue(1);
    // this.unidadForm.get('idProforma')?.setValue(1);
    // this.unidadForm.get('tipoAcuerdo')?.setValue('VENTA');
    // this.unidadForm.get('fechaAcuerdo')?.setValue(new Date());
    // this.unidadForm.get('fechaEntrega')?.setValue(new Date());
    // // this.unidadForm.get('idAgencia')?.setValue(1);
    // // this.unidadForm.get('idEmpaque')?.setValue(1);
    // // this.unidadForm.get('idSolicitud')?.setValue(1);


    //Crea una variable de tipo Date con la fecha y hora actual en zona horaria local





    // this.unidadForm.get('certDnrpa')?.setValue(1);
    // this.unidadForm.get('cert01')?.setValue(1);
    // this.unidadForm.get('dominio')?.setValue(1);
    // this.unidadForm.get('qrIdentifier')?.setValue(1);






  }

  editarUnidad(): void {
    this.isEditing = true;
    this.isCreating = false;
    this.enableForm();
  }


  // Método para agregar una nueva incidencia al FormArraydfe
  addIncidencia(): void {
    const incidenciaGroup = this.fb.group({
      tipo: [{ value: '', disabled: !this.isEditing && !this.isCreating }],
      descripcion: [{ value: '', disabled: !this.isEditing && !this.isCreating }],
      accion: [{ value: '', disabled: !this.isEditing && !this.isCreating }],
      valor: [{ value: 0, disabled: !this.isEditing && !this.isCreating }],
      moneda: [{ value: '', disabled: !this.isEditing && !this.isCreating }]
    });
    this.incidencias.push(incidenciaGroup);
  }

  // Método para eliminar una incidencia del FormArray
  removeIncidencia(index: number): void {
    this.incidencias.removeAt(index);
  }

  loadEmpaques(): void {
    this.empaqueService.getAll().subscribe(
      (data) => {
        this.empaqueOptions = data.map(empaque => ({
          label: empaque.nombreProveedor + " " + empaque.idEmpaque, // Ajusta según la propiedad que quieras mostrar
          value: empaque.idEmpaque
        }));
      },
      (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al cargar empaques' });
      }
    );
  }


  loadBillOfLandings(): void {
    this.billOfLandingService.getAll().subscribe(
      (data) => {
        this.billOfLandingOptions = data.map(bill => ({
          label: bill.idBl, // Ajusta según la propiedad que quieras mostrar
          value: bill.idBl
        }));

        console.log('Bill of Landings cargados:', this.billOfLandingOptions);

      },
      (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al cargar Bill of Landings' });
      }
    );
  }

  loadTiposUnidad(): void {
    this.tiposUnidadService.getAllTiposUnidad().subscribe(
      (data) => {
        // console.log('Tipos de unidad cargados:', data);
        this.tipoUnidadOptions = data.map(tipoUnidad => ({
          label: tipoUnidad.tipoUnidad,
          value: tipoUnidad.tipoUnidad
        }));
      },
      (error) => {
        // console.log('Error al cargar tipos de unidad:', error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al cargar tipos de unidad' });
      }
    );
  }


  loadMaestroUnidades(): void {
    this.unidadService.getUnidades().subscribe(
      (data: Unidad[]) => {
        console.log('Unidades cargadas:', data);  // Aquí verifica la respuesta de la API

        this.unidades = data;
        this.vinsCargados = this.unidades.map(unidad => unidad.vin);



      },
      (error) => {
        // console.log('Error al cargar unidades:', error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al cargar unidades' });
      }
    );
  }

  loadProformas(): void {
    this.proformaService.getAllProformas().subscribe(
      (data) => {
        this.proformaOptions = data.map(proforma => ({
          label: proforma.idProforma + " " + proforma.fechaEntrega, // Ajusta según la propiedad que quieras mostrar
          value: proforma.idProforma
        }));
      },
      (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al cargar proformas' });
      }
    );
  }

  handleSolicitudChange(): void {
    //clear el array de proformas si el IdSolicitud del formulario es null
    if (!this.unidadForm.get('idSolicitud')?.value) {
      this.proformaOptions = [];
      this.billOfLandingOptions = [];
      this.facturaOptions = [];
      return;
    }

    this.loadProformasByIdSolicitud();
  }


  handleEmpaqueChange(): void {
    //clear el array de billsofLandin si el idEmpaque del formulario es null
    if (!this.unidadForm.get('idEmpaque')?.value) {
      this.billOfLandingOptions = [];
      this.facturaOptions = [];
      return;
    }

    this.loadBillOfLandingsByEmpaqueId();
  }

  handleBillOfLandingChange(): void {
    //clear el array de facturas si el idBl del formulario es null
    if (!this.unidadForm.get('idBl')?.value) {
      this.facturaOptions = [];
      return;
    }

    this.loadFacturaByBl();
  }


  loadProformasByIdSolicitud(): void {
    const idSolicitud = this.unidadForm.get('idSolicitud')?.value;

    if (!idSolicitud) {
      return;
    }

    this.proformaService.getProformaBsyId(idSolicitud).subscribe(
      (data) => {
        if (!data || data.length === 0) { // Manejo de respuesta vacía o sin datos
          this.messageService.add({ severity: 'info', summary: 'Sin datos', detail: 'No hay proformas disponibles para esta solicitud' });
          this.proformaOptions = []; // Limpia las opciones previas
          return;
        }

        this.proformaOptions = data.map(proforma => ({
          label: `${proforma.idProforma} ${proforma.fechaEntrega}`, // Ajusta según la propiedad que quieras mostrar
          value: proforma.idProforma
        }));
      },
      (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al cargar proformas' });
      }
    );
  }


  loadBillOfLandingsByEmpaqueId(): void {
    const idEmpaque = this.unidadForm.get('idEmpaque')?.value;

    if (!idEmpaque) {
      return;
    }

    this.billOfLandingService.getBillOfLandingsByEmpaqueId(idEmpaque).subscribe(
      (data) => {
        if (!data || data.length === 0) { // Manejo de respuesta vacía o sin datos
          this.messageService.add({ severity: 'info', summary: 'Sin datos', detail: 'No hay Bill of Landings disponibles para este empaque' });
          this.billOfLandingOptions = []; // Limpia las opciones previas
          return;
        }

        this.billOfLandingOptions = data.map(bill => ({
          label: bill.idBl, // Ajusta según la propiedad que quieras mostrar
          value: bill.idBl
        }));
      },
      (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al cargar Bill of Landings' });
      }
    );
  }

  loadFacturaByBl(): void {
    const idBl = this.unidadForm.get('idBl')?.value;

    if (!idBl) {
      return;
    }

    this.facturaService.getFacturaComercialByBL(idBl).subscribe(
      (data) => {
        if (!data || data.length === 0) { // Manejo de respuesta vacía o sin datos
          this.messageService.add({ severity: 'info', summary: 'Sin datos', detail: 'No hay facturas disponibles para este ID' });
          this.facturaOptions = []; // Limpia las opciones previas
          return;
        }

        this.facturaOptions = data.map(factura => ({
          label: factura.idFactura, // Ajusta según la propiedad que quieras mostrar
          value: factura.idFactura
        }));
      },
      (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al cargar facturas' });
      }
    );
  }

  loadCatalogo(): void {
    this.catalogoService.getAllItems().subscribe(
      (data: Catalogo[]) => {
        // console.log('Unidades cargadas:', data);  // Aquí verifica la respuesta de la API
        this.catalogo = data.map(catalogo => ({ label: catalogo.nombre, value: catalogo.idUnidad }));
      },
      (error) => {
        // console.log('Error al cargar unidades:', error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al cargar unidades' });
      }
    );
  }



  loadAgencias(): void {
    this.agenciaService.getAll().subscribe(
      (data: Agencia[]) => {
        // console.log('Agencias cargadas:', data);  // Aquí verifica la respuesta de la API

        // Transforma el array para que tenga las propiedades label y value
        this.agencias = data.map(agencia => ({
          label: agencia.nombre,
          value: agencia.idAgencia
        }));

        ;
      },
      (error) => {
        // console.log('Error al cargar agencias:', error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al cargar agencias' });
      }
    );
  }

  loadAcuerdos(): void {
    this.acuerdoService.getAll().subscribe(
      (data: Acuerdo[]) => {
        // console.log('Acuerdos cargados:', data);  // Aquí verifica la respuesta de la API

        // Transforma el array para que tenga las propiedades label y value
        this.acuerdos = data.map(acuerdo => ({
          label: acuerdo.tipoAcuerdo,
          value: acuerdo.tipoAcuerdo
        }));
      },
      (error) => {
        // console.log('Error al cargar acuerdos:', error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al cargar acuerdos' });
      }
    );
  }



  // Manejo de búsqueda de registros
  searchUnidad(): void {
    const vin = this.unidadForm.get('vin')?.value;
    const tipoUnidad = this.unidadForm.get('tipoUnidad')?.value;
    const idUnidad = this.unidadForm.get('idUnidad')?.value;
    console.log('Buscando unidad con VIN:', vin, 'Tipo:', tipoUnidad, 'ID:', idUnidad);  // Verifica que los valores sean correctos
    this.unidadService.getUnidadById(vin, tipoUnidad, idUnidad).subscribe(
      (unidad: Unidad) => {
        console.log('Unidad encontrada:', unidad);  // Verifica si la unidad es la esperada
        this.selectedUnidad = unidad;
        this.unidadForm.patchValue(unidad);
        this.isEditing = true;
      },
      (error) => {
        console.log('Error al buscar unidad:', error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Unidad no encontrada' });
      }
    );
  }

  // Crear una nueva unidad
  createUnidad(): void {
    let faltanCampos=false;
    let creatingVin = this.unidadForm.get('vin')?.value;

    console.log('Formulario:', this.unidadForm.value);  // Verifica que los valores sean correctos

    // Log de errores de cada control antes de guardar
    Object.keys(this.unidadForm.controls).forEach(key => {
      const controlErrors = this.unidadForm.get(key)?.errors;
      if (controlErrors) {
        console.log(`Errores en el campo ${key}:`, controlErrors);

        //Marca el input como ng-dirty o algo similar
        this.unidadForm.get(key)?.markAsDirty();
        this.unidadForm.get(key)?.markAsTouched();

        faltanCampos=true;
      }
    });

    // Log de errores de cada incidencia antes de guardar
    this.incidencias.controls.forEach((incidencia, index) => {
      Object.keys((incidencia as FormGroup).controls).forEach(key => {
        const controlErrors = (incidencia as FormGroup).get(key)?.errors;
        if (controlErrors) {
          console.log(`Errores en el campo ${key} de la incidencia ${index}:`, controlErrors);
          faltanCampos=true;

        }
      });
    });

    if(faltanCampos){
      this.messageService.add({severity:'error', summary: 'Error', detail: 'Formulario inválido: Complete los campos obligatorios'});
      return;
    }

    console.log('Creando unidad:', this.unidadForm.value);  // Verifica que los valores sean correctos

    if (this.unidadForm.valid) {
      this.unidadService.createUnidad(this.unidadForm.value  ).subscribe(
        () => {

          // console.log(this.inspeccion);
          console.log(this.inspeccionDetalle);

          // Ejecuta la peticion POST para crear una inspección preentrega, utilizando el array de incidencias del formulario
          this.inspeccionPreentregaService.createInspeccion({
            vin: this.unidadForm.get('vin')?.value,
            tipoUnidad: this.unidadForm.get('tipoUnidad')?.value,
            idUnidad: this.unidadForm.get('idUnidad')?.value,
            nroMotor: this.unidadForm.get('nroMotor')?.value,
            idPreentrega: 1,
            tipoProveedor: 'TRA',
            idProveedor: 1,
            fechaEntrega: this.unidadForm.get('fechaEntrega')?.value
          }).subscribe(
            () => {
              // this.messageService.add({severity:'success', summary: 'Éxito', detail: 'Inspección preentrega creada correctamente'});

              // Ejecuta la peticion POST para crear un detalle de inspección preentrega por cada registro del array de incidencias del formulario
              this.incidencias.controls.forEach((incidencia, index) => {

                this.inspeccionDetalleService.createDetalle({
                    vin: this.unidadForm.get('vin')?.value,
                    tipoUnidad: this.unidadForm.get('tipoUnidad')?.value,
                    idUnidad: this.unidadForm.get('idUnidad')?.value,
                    nroMotor: this.unidadForm.get('nroMotor')?.value,
                    idPreentrega: 1,
                    nroOrden: index + 1,
                    tipoPunto: (incidencia as FormGroup).get('tipo')?.value,
                    descripcion: (incidencia as FormGroup).get('descripcion')?.value,
                    accion: (incidencia as FormGroup).get('accion')?.value,
                    valor: parseFloat((incidencia as FormGroup).get('valor')?.value),
                    idMoneda: (incidencia as FormGroup).get('moneda')?.value
                    // descripcionPuntoInspeccion: '',
                    // descripcionInspeccionPreentrega: ''
                }).subscribe(
                () => {
                  // this.messageService.add({severity:'success', summary: 'Éxito', detail: 'Detalle de inspección preentrega creado correctamente'});
                },
                (error) => {
                  this.messageService.add({severity:'error', summary: 'Error', detail: 'Error al crear el detalle de inspección preentrega'});
                }
                );
                });
            },
            (error) => {
              this.messageService.add({severity:'error', summary: 'Error', detail: 'Error al crear la inspección preentrega'});
            }
          );


          console.log('Regenerando QR de la unidad:', this.unidadForm.value);
          this.unidadService.regenerarQr(
            this.unidadForm.get('vin')?.value,
            this.unidadForm.get('tipoUnidad')?.value,
            this.unidadForm.get('idUnidad')?.value,
            this.unidadForm.get('nroMotor')?.value
          ).subscribe(
            () => {
              this.messageService.add({severity:'success', summary: 'Éxito', detail: 'QR regenerado correctamente'});
            },
            (error) => {
              this.messageService.add({severity:'error', summary: 'Error', detail: 'Error al regenerar el QR'});
            }
          );


          this.messageService.add({severity:'success', summary: 'Éxito', detail: 'Unidad creada correctamente'});
          this.loadMaestroUnidades();
          this.isEditing = false;
          this.isCreating = false;
          this.disableForm();
          //this.unidadForm.reset();
          this.searchUnidadGeneralvin(creatingVin);

        },
        (error) => {
          if (error.status === 400 && error?.error?.details) {
            this.messageService.add({severity:'error', summary: 'Error', detail: 'Error al crear la unidad: ' + error.error.details});
          } else {
            this.messageService.add({severity:'error', summary: 'Error', detail: 'Error al crear la unidad'});
          }
        }
      );
    } else {
      this.messageService.add({severity:'error', summary: 'Error', detail: 'Formulario inválido'});
    }
  }


  downloadQR(): void {

    if (this.selectedUnidad) {
      console.log('Regenerando QR de la unidad:', this.unidadForm.value);
      this.unidadService.regenerarQr(
        this.unidadForm.get('vin')?.value,
        this.unidadForm.get('tipoUnidad')?.value,
        this.unidadForm.get('idUnidad')?.value,
        this.unidadForm.get('nroMotor')?.value
      ).subscribe(
        () => {
          this.messageService.add({severity:'success', summary: 'Éxito', detail: 'QR regenerado correctamente'});

          this.unidadService.obtenerImagenQr(this.selectedUnidad!.vin, this.selectedUnidad!.tipoUnidad, this.selectedUnidad!.idUnidad, this.selectedUnidad!.nroMotor).subscribe(
            (qrBlob: Blob) => {

              const url = window.URL.createObjectURL(qrBlob);
              const a = document.createElement('a');
              a.href = url;
              a.download = 'qr.png';
              a.click();
              window.URL.revokeObjectURL(url);
            },
            (error) => {
              this.messageService.add({severity:'error', summary: 'Error', detail: 'Error al descargar el QR'});
            }
          );

        },
        (error) => {
          this.messageService.add({severity:'error', summary: 'Error', detail: 'Error al regenerar el QR'});
        }
      );
    }
  }

  // Editar la unidad seleccionada
  updateUnidad(): void {
    let faltanCampos=false;

    // Log de errores de cada control antes de guardar
    Object.keys(this.unidadForm.controls).forEach(key => {
      const controlErrors = this.unidadForm.get(key)?.errors;
      if (controlErrors) {
        console.log(`Errores en el campo ${key}:`, controlErrors);

        //Marca el input como ng-dirty o algo similar
        this.unidadForm.get(key)?.markAsDirty();

        faltanCampos=true;
      }
    });

    // Log de errores de cada incidencia antes de guardar
    this.incidencias.controls.forEach((incidencia, index) => {
      Object.keys((incidencia as FormGroup).controls).forEach(key => {
        const controlErrors = (incidencia as FormGroup).get(key)?.errors;
        if (controlErrors) {
          console.log(`Errores en el campo ${key} de la incidencia ${index}:`, controlErrors);
          faltanCampos=true;

        }
      });
    });

    if(faltanCampos){
      this.messageService.add({severity:'error', summary: 'Error', detail: 'Formulario inválido: Complete los campos obligatorios'});
      return;
    }

    console.log('Editando unidad:', this.unidadForm.value);  // Verifica que los valores sean correctos

    if (this.unidadForm.valid && this.selectedUnidad) {

       this.inspeccionDetalleOri.forEach((incidenciaDetalle, index) => {

          this.inspeccionDetalleService.deleteDetalle( this.selectedUnidad!.vin, this.selectedUnidad!.tipoUnidad, this.selectedUnidad!.idUnidad, this.selectedUnidad!.nroMotor, incidenciaDetalle.idPreentrega, incidenciaDetalle.nroOrden)
          .subscribe(
          () => {
            // this.messageService.add({severity:'success', summary: 'Éxito', detail: 'Detalle de inspección preentrega actualizado correctamente'});
          },
          (error) => {
            this.messageService.add({severity:'error', summary: 'Error', detail: 'Error al actualizar el detalle de inspección preentrega'});
          }
        );
      });


      // Ejecuta la peticion PUT para actualizar el array de inspeccion detalle, utilizando el array de incidencias del formulario
      this.incidencias.controls.forEach((incidencia, index) => {

        this.inspeccionDetalleService.createDetalle({
            vin: this.unidadForm.get('vin')?.value,
            tipoUnidad: this.unidadForm.get('tipoUnidad')?.value,
            idUnidad: this.unidadForm.get('idUnidad')?.value,
            nroMotor: this.unidadForm.get('nroMotor')?.value,
            idPreentrega: 1,
            nroOrden: index + 1,
            tipoPunto: (incidencia as FormGroup).get('tipo')?.value,
            descripcion: (incidencia as FormGroup).get('descripcion')?.value,
            accion: (incidencia as FormGroup).get('accion')?.value,
            valor: parseFloat((incidencia as FormGroup).get('valor')?.value),
            idMoneda: (incidencia as FormGroup).get('moneda')?.value
            // descripcionPuntoInspeccion: '',
            // descripcionInspeccionPreentrega: ''
        }).subscribe(
        () => {
          this.messageService.add({severity:'success', summary: 'Éxito', detail: 'Detalle de inspección preentrega actualizado correctamente'});
        },
        (error) => {
          this.messageService.add({severity:'error', summary: 'Error', detail: 'Error al actualizar el detalle de inspección preentrega'});
        }
        );
        });


      this.unidadService.updateUnidad(
        { vin: this.selectedUnidad.vin, tipoUnidad: this.selectedUnidad.tipoUnidad, idUnidad: this.selectedUnidad.idUnidad, nroMotor: this.selectedUnidad.nroMotor },
        this.unidadForm.value
      ).subscribe(
        () => {
          this.messageService.add({severity:'success', summary: 'Éxito', detail: 'Unidad actualizada correctamente'});

          this.loadMaestroUnidades();
          this.isEditing = false;
          this.isCreating = false;
          this.disableForm();
          //this.unidadForm.reset();
          this.searchUnidadGeneral(this.unidadForm.get('vin')?.value);

        },
        (error) => {
          if (error.status === 400 && error.error?.details) {
            this.messageService.add({severity:'error', summary: 'Error', detail: 'Error al actualizar la unidad: ' + error.error.details});
          } else {
            this.messageService.add({severity:'error', summary: 'Error', detail: 'Error al crear la unidad'});
          }
        }
      );
    }
  }

  // Confirmar eliminación de una unidad
  deleteUnidad(): void {
    console.log('Eliminando unidad:', this.selectedUnidad);

    if (this.selectedUnidad) {
      // console.log('paso');

      this.confirmationService.confirm({
        message: '¿Está seguro de eliminar la unidad?',
        accept: () => {

          //Si hay inspecciones detalle, se eliminan



          if(this.inspeccionDetalle.length>0){
            // Ejecuta la peticion DELETE para eliminar el detalles de la inspección preentrega por cada registro del array de incidencias del formulario
            this.inspeccionDetalle.forEach((incidenciaDetalle, index) => {
              this.inspeccionDetalleService.deleteDetalle( this.selectedUnidad!.vin, this.selectedUnidad!.tipoUnidad, this.selectedUnidad!.idUnidad, this.selectedUnidad!.nroMotor, incidenciaDetalle.idPreentrega, incidenciaDetalle.nroOrden).subscribe(
                () => {
                  this.messageService.add({severity:'success', summary: 'Éxito', detail: 'Detalle de inspección preentrega eliminado correctamente'});

                  //Elimina la inspeccion preentrega
                  this.inspeccionPreentregaService.deleteInspeccion(this.selectedUnidad!.vin, this.selectedUnidad!.tipoUnidad, this.selectedUnidad!.idUnidad, this.selectedUnidad!.nroMotor, 1).subscribe(
                    () => {
                      this.messageService.add({severity:'success', summary: 'Éxito', detail: 'Inspección preentrega eliminada correctamente'});

                      this.unidadService.deleteUnidad({ vin: this.selectedUnidad!.vin, tipoUnidad: this.selectedUnidad!.tipoUnidad, idUnidad: this.selectedUnidad!.idUnidad, nroMotor: this.selectedUnidad!.nroMotor }).subscribe({
                        next: () => {
                          this.messageService.add({severity:'success', summary: 'Éxito', detail: 'Unidad eliminada correctamente'});
                          this.loadMaestroUnidades();
                          this.unidadForm.reset();
                          this.isEditing = false;
                          this.selectedUnidad = null;
                          this.disableForm();
                          this.incidencias.clear();
                        },
                        error: () => {
                          this.messageService.add({severity:'error', summary: 'Error', detail: 'Error al eliminar la unidad'});
                        }
                      });

                    },
                    (error) => {
                      this.messageService.add({severity:'error', summary: 'Error', detail: 'Error al eliminar la inspección preentrega'});
                    }
                  );

                },
                (error) => {
                  this.messageService.add({severity:'error', summary: 'Error', detail: 'Error al eliminar el detalle de inspección preentrega'});
                }
              );
            });
          } else {

              //Elimina la inspeccion preentrega
              this.inspeccionPreentregaService.deleteInspeccion(this.selectedUnidad!.vin, this.selectedUnidad!.tipoUnidad, this.selectedUnidad!.idUnidad, this.selectedUnidad!.nroMotor, 1).subscribe(
                () => {
                  this.messageService.add({severity:'success', summary: 'Éxito', detail: 'Inspección preentrega eliminada correctamente'});

                  this.unidadService.deleteUnidad({ vin: this.selectedUnidad!.vin, tipoUnidad: this.selectedUnidad!.tipoUnidad, idUnidad: this.selectedUnidad!.idUnidad, nroMotor: this.selectedUnidad!.nroMotor }).subscribe({
                    next: () => {
                      this.messageService.add({severity:'success', summary: 'Éxito', detail: 'Unidad eliminada correctamente'});
                      this.loadMaestroUnidades();
                      this.unidadForm.reset();
                      this.isEditing = false;
                      this.selectedUnidad = null;
                      this.disableForm();
                      this.incidencias.clear();
                    },
                    error: () => {
                      this.messageService.add({severity:'error', summary: 'Error', detail: 'Error al eliminar la unidad'});
                    }
                  });

                },
                (error) => {
                  this.messageService.add({severity:'error', summary: 'Error', detail: 'Error al eliminar la inspección preentrega'});
                }
              );
          }
        }
      });
    }
  }

  callCreateUnidadVendida(): void {
    this.router.navigate(['/maestro-unidades-vendidas/', this.selectedUnidad?.vin]);
  }

  // Manejo de cancelación
  cancelEdit(): void {
    this.unidadForm.reset();

    // Limpiar el FormArray de incidencias
    while (this.incidencias.length !== 0) {
      this.incidencias.removeAt(0);
    }

    this.isEditing = false;
    this.isCreating = false;
    this.selectedUnidad = null;
    this.disableForm();
  }


  enableForm(): void {
    Object.keys(this.unidadForm.controls).forEach(controlName => {
      const control = this.unidadForm.get(controlName);
      control?.enable();
      control?.updateValueAndValidity(); // Asegúrate de actualizar la validez
    });
    this.incidencias.controls.forEach(incidencia => {
      (incidencia as FormGroup).enable();
      (incidencia as FormGroup).updateValueAndValidity(); // Asegúrate de actualizar la validez
    });
    console.log('Formulario habilitado y actualizado:', this.unidadForm);
    Object.keys(this.unidadForm.controls).forEach(controlName => {
      this.unidadForm.get(controlName)?.enable();
    });
    this.incidencias.controls.forEach(incidencia => {
      (incidencia as FormGroup).enable();
    });
  }

  disableForm(): void {
    Object.keys(this.unidadForm.controls).forEach(controlName => {
      this.unidadForm.get(controlName)?.disable();
    });
    this.incidencias.controls.forEach(incidencia => {
      (incidencia as FormGroup).disable();
    });
  }

}
